import axios from "axios";
import { toast } from "react-toastify";
import NotFound from "../views/NotFound";

class AxiosService {
  constructor() {
    const instance = axios.create();
    instance.interceptors.request.use(this._handleRequest);
    instance.interceptors.response.use(this._handleResponse, this._handleError);
    this.instance = instance;
  }

  _handleRequest = (config) => {
    // Add authentication token to the request headers
    const token = sessionStorage.getItem("token") ?? null;
    if (token) {
      config.headers["Authorization"] = `Bearer ${JSON.parse(token)}`;
    }

    // Add Content-Type header for JSON data
    config.headers["Content-Type"] = "application/json";

    return config;
  };

  _handleResponse = (response) => {
    return response.data;
  };

  _handleError = (error) => {
    if (error.code === "ERR_NETWORK") {
      return toast.error("Please check your network connection!");
    }

    if (error?.response?.status === 404) {
      window.location.replace("/404");
    }

    if (error?.response?.status === 401 || error?.response?.status === 403) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.replace("/");
    }

    return Promise.reject(error.response?.data);
  };

  get(url, params) {
    return this.instance.get(url, { params });
  }

  async post(url, data) {
    return await this.instance.post(url, data);
  }

  patch(url, data) {
    return this.instance.patch(url, data);
  }

  delete(url, data) {
    return this.instance.delete(url, data);
  }
}

export default new AxiosService();
