import { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import Footer from "../../../components/layout/Footer";
import BreadCrumb from "../../../components/layout/BreadCrumb";
import EmployeeService from "../../../services/EmployeeService";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import FormSwitch from '../../../components/form/formSwitch';
import { uppercaseFirst, uppercaseWord } from "../../../utils/helper";

function EmployeeEdit() {
  const location = useLocation();
  const employeeId = location.state?.employeeId || {};
  const [departments, setDepartments] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [accesstypes, setAccesstypes] = useState([]);
  const [stafflocation, setStaffLocation] = useState([]);
  const [genders, setGenders] = useState([]);
  const [maritalStatuses, setMaritalStatuses] = useState({});
  const [employmentStatuses, setEmploymentStatuses] = useState({});
  const employeeService = EmployeeService();
  const [employeeData, setEmployeeData] = useState({});
  const { loading = true } = useSelector((state) => state.employee);
  const [teamMembersWithoutSupervisor, setTeamMembersWithoutSupervisor] = useState([]);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const hiredOnDateElement = useRef();
  const birthDateElement = useRef();
  const [passwordValue, setPasswordValue] = useState({password: '', password_confirmation: ''});

  useEffect(() => {
    (async () => {
      const response = await employeeService.findById(employeeId);
      setEmployeeData(employeeService.flatenEmployeeObject(response));
      setIsSupervisor(response?.employment_information?.is_supervisor);
      setSelectedRoles(response?.personal_details?.roles.map((role) => role.id));
    })();

    (async () => {
      const response = await employeeService.getEmployeeConfigurations();

      setGenders(response?.genders);
      setMaritalStatuses(response?.marital_statuses);
      setDepartments(response?.departments);
      setDesignations(response?.designations);
      setAccesstypes(response?.access_types);
      setEmploymentStatuses(response?.employment_statuses);
      setStaffLocation(response?.locations);
      setTeamMembersWithoutSupervisor(response?.team_members_without_a_supervisor);
      setRoles(response?.roles);
    })();

  }, []);

  const handleOnUpdate = () => {
    (async () => {
      await employeeService.update(employeeData);
      window.location.reload();
    })();
  };

  const handleRemoveTeamMember = (event, teamMember) => {
    const result = employeeData?.team_members.filter((team) => {
      return team.employee_id !== teamMember.employee_id
    });

    setEmployeeData((prev) => ({ ...prev, team_members: result }));
    setTeamMembersWithoutSupervisor(prev => [...prev, teamMember]);
  }

  const handleOnchangeTeamMemberWithoutSupervisor = (selectedTeamMemberId) => {
    if (typeof selectedTeamMemberId === 'string' && selectedTeamMemberId.length === 0) {
      return;
    }

    const selectedTeamMember = teamMembersWithoutSupervisor?.filter((team) => {
      return team.employee_id === parseInt(selectedTeamMemberId);
    });

    const result = teamMembersWithoutSupervisor.filter((team) => {
      return team.employee_id !== parseInt(selectedTeamMemberId)
    });

    setTeamMembersWithoutSupervisor(result);

    const newteamMembers = [...employeeData.team_members, selectedTeamMember[0]];

    setEmployeeData((prev) => ({ ...prev, team_members: newteamMembers }));
  }

  const handleIsSupervisorSwitch = () => {
    setIsSupervisor(!isSupervisor);

    setEmployeeData((prev) => ({ ...prev, is_supervisor: !isSupervisor }));
  }

  const handleOnClickSetUserRoles = (roleId) => {
    let currentRoles = [...employeeData.roles];

    if (selectedRoles?.includes(parseInt(roleId))) {
      const unSelected = selectedRoles.filter((selectedRole) => {return selectedRole !== parseInt(roleId)});
      currentRoles.pop(parseInt(roleId));
      setEmployeeData((prev) => ({...prev, roles: currentRoles}));
      setSelectedRoles(unSelected);
      return;
    }

    let selected = [...selectedRoles];
    selected.push(parseInt(roleId));
    setSelectedRoles(selected);

    currentRoles.push(parseInt(roleId));
    setEmployeeData((prev) => ({...prev, roles: currentRoles}));
  }

  const getTeamMemberWithoutSupervisor = () => {
    return teamMembersWithoutSupervisor?.map((member) => {
      if (member.employee_id !== employeeData.id) {
        return (<option key={member.employee_id} value={member.employee_id}>{uppercaseWord(member.name)}</option>)
      }
    })
  }

  const handlePasswordUpdate = async () => {
    await employeeService.passwordUpdate(employeeId, passwordValue);
    window.location.reload();
  }

  return (
    <div className="main-content">
      <BreadCrumb
        title="Edit Employee"
        parentLink="#"
        parentTitle="Employee Records"
        currentTitle="Edit Employee"
      />
      <div className="separator-breadcrumb border-top"></div>

      {
        loading ? <Loader /> :
          <div className="col-md-12">
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Personal Details</div>
                    <form>
                      <div className="row">

                        <div className="form-group mb-3">
                          <label htmlFor="firstName1">First name</label>
                          <input
                            className="form-control"
                            id="firstName"
                            type="text"
                            onChange={(event) => { setEmployeeData((prev) => ({ ...prev, first_name: event.target.value })) }}
                            value={uppercaseFirst(employeeData.first_name) ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Middle name</label>
                          <input
                            className="form-control"
                            id="middleNme"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, middle_name: event.target.value }))
                            }}
                            value={uppercaseFirst(employeeData.middle_name) ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Last name</label>
                          <input
                            className="form-control"
                            id="lastName"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, last_name: event.target.value }))
                            }}
                            value={uppercaseFirst(employeeData.last_name) ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="picker2">Birth date</label>
                          <input
                            className="form-control"
                            id="dateOfBirth"
                            placeholder="yyyy-mm-dd"
                            onChange={(event) => setEmployeeData((prev) => ({ ...prev, date_of_birth: event.target.value }))}
                            value={employeeData.date_of_birth ?? ""}
                            ref={birthDateElement}
                            onClick={() => birthDateElement.current.showPicker()}
                            type="datetime-local"
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Gender</label>
                          <select
                            className="form-control"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, gender: event.target.value }))
                            }}
                            defaultValue={""}
                          >
                            <option value="Select Gender">Select Gender</option>
                            {
                              genders &&
                              Object.entries(genders).map(([key, value]) => {
                                return (<option selected={parseInt(key) === parseInt(employeeData.gender)} key={key} value={key}>{uppercaseFirst(value)}</option>)
                              })
                            }
                          </select>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Marital Status</label>
                          <select
                            className="form-control"
                            onChange={(event) => { setEmployeeData((prev) => ({ ...prev, marital_status: event.target.value })) }}
                            defaultValue={""}
                          >
                            <option value="Select a marital status">Select status </option>
                            {
                              maritalStatuses &&
                              Object.entries(maritalStatuses).map(([key, value]) => {
                                return (<option selected={parseInt(key) === parseInt(employeeData.marital_status)} key={key} value={key}>{uppercaseFirst(value)}</option>)
                              })
                            }
                          </select>
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Employment Information</div>
                    <form>
                      <div className="row">
                        <div className="form-group mb-3">
                          <label htmlFor="firstName1">Hired On</label>
                          <input
                            className="form-control"
                            id="hiredOn"
                            placeholder="yyyy-mm-dd"
                            onChange={(event) => { setEmployeeData((prev) => ({ ...prev, hired_on: event.target.value })) }}
                            value={employeeData.hired_on ?? ""}
                            type="datetime-local"
                            min="1990-01-01" max="2090-12-31"
                            ref={hiredOnDateElement}
                            onClick={() => hiredOnDateElement.current.showPicker()}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Department</label>
                          <select
                            className="form-control"
                            onChange={(event) => { setEmployeeData((prev) => ({ ...prev, department: event.target.value })) }}
                            defaultValue={""}
                          >
                            <option value="Select a department"> Select</option>
                            {
                              departments?.map((item) => (
                                <option selected={parseInt(item.id) === parseInt(employeeData.department)} key={item.id} value={item.id}>{item.name}</option>
                              ))
                            }
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Designation</label>
                          <select
                            className="form-control"
                            onChange={(event) => { setEmployeeData((prev) => ({ ...prev, designation: event.target.value })) }}
                            defaultValue={""}
                          >
                            <option value="Select a designation"> Select</option>
                            {
                              designations?.map((item) => (
                                <option selected={parseInt(item.id) === parseInt(employeeData.designation)} key={item.id} value={item.id}>{item.name}</option>
                              ))
                            }
                          </select>
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="picker2">Access Type</label>
                          <select
                            className="form-control"
                            onChange={(e) => { setEmployeeData((prev) => ({ ...prev, access_type: e.target.value })) }}
                            defaultValue={""}
                          >
                            <option value="Select an access type"> Select</option>
                            {
                              accesstypes?.map((item) => (
                                <option selected={parseInt(item.id) === parseInt(employeeData.access_type)} key={item.id} value={item.id}>{item.name}</option>
                              ))
                            }
                          </select>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Location</label>
                          <select
                            className="form-control"
                            onChange={(e) => { setEmployeeData((prev) => ({ ...prev, location: e.target.value })) }}
                            defaultValue={""}
                          >
                            <option value="Select a location"> Select</option>
                            {
                              stafflocation?.map((item) => (
                                <option selected={parseInt(item.id) === parseInt(employeeData.location)} key={item.id} value={item.id}>{uppercaseFirst(item.name)}</option>
                              ))
                            }
                          </select>
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Employment Status</label>
                          <select
                            className="form-control"
                            onChange={(event) => { setEmployeeData((prev) => ({ ...prev, employment_status: event.target.value })) }}
                            defaultValue={""}
                          >
                            <option value="Select an employment status"> Select</option>
                            {
                              employmentStatuses &&
                              Object.entries(employmentStatuses).map(([key, value]) => {
                                return (<option selected={parseInt(key) === employeeData.employment_status} key={key} value={key}>{uppercaseFirst(value)}</option>)
                              })
                            }
                          </select>
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Address And Contact Informataion</div>
                    <form>
                      <div className="row">
                        <div className="form-group mb-3">
                          <label htmlFor="firstName1">Street</label>
                          <input
                            className="form-control"
                            id="street"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, street: event.target.value }))
                            }}
                            value={employeeData?.street ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">City</label>
                          <input
                            className="form-control"
                            id="city"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, city: event.target.value }))
                            }}
                            value={employeeData?.city ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="lastName1">State</label>
                          <input
                            className="form-control"
                            id="state"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, state: event.target.value }))
                            }}
                            value={employeeData?.state ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker2">Zip Code</label>
                          <input
                            className="form-control"
                            id="zipCode"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, zip_code: event.target.value }))
                            }}
                            value={employeeData?.zip_code ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Personal Email</label>
                          <input
                            className="form-control"
                            id="email"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, personal_email: event.target.value }))
                            }}
                            value={employeeData?.personal_email ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Work Email</label>
                          <input
                            className="form-control"
                            id="workEmail"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, work_email: event.target.value }))
                            }}
                            value={employeeData?.work_email ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Mobile Phone</label>
                          <input
                            className="form-control"
                            id="mobileNumber"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, mobile_phone: event.target.value }))
                            }}
                            value={employeeData?.mobile_phone ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="picker1">Work Phone</label>
                          <input
                            className="form-control"
                            id="workPhone"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, work_phone: event.target.value }))
                            }}
                            value={employeeData?.work_phone ?? ""}
                          />
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Emergency Contact Information</div>
                    <form>
                      <div className="row">
                        <div className="form-group mb-3">
                          <label htmlFor="firstName1">Full Name</label>
                          <input
                            className="form-control"
                            id="fullName"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, emergency_contact_full_name: event.target.value }))
                            }}
                            value={employeeData?.emergency_contact_full_name ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Relationship</label>
                          <input
                            className="form-control"
                            id="relationship"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, emergency_contact_relationship: event.target.value }))
                            }}
                            value={employeeData?.emergency_contact_relationship ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Phone</label>
                          <input
                            className="form-control"
                            id="phone"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, emergency_contact_phone: event.target.value }))
                            }}
                            value={employeeData?.emergency_contact_phone ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="picker2">Email</label>
                          <input
                            className="form-control"
                            id="email"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, emergency_contact_email: event.target.value }))
                            }}
                            value={employeeData?.emergency_contact_email ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="picker1">Address</label>
                          <input
                            className="form-control"
                            id="address"
                            type="text"
                            onChange={(event) => {
                              setEmployeeData((prev) => ({ ...prev, emergency_contact_address: event.target.value }))
                            }}
                            value={employeeData?.emergency_contact_address ?? ""}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Set roles</div>
                    <>
                      {
                        (isSupervisor) ?
                          <>
                            <label className='mb-3'>Activate supervisor role</label>
                            <FormSwitch text={'Supervisor role enabled'} onChange={handleIsSupervisorSwitch} checked={isSupervisor} />
                            <fieldset className='mt-3'>
                              <label>Team members</label>
                              <div className='row'>
                                <div className="col-lg-6 form-group mt-3">
                                  <div className="tagBox case-sensitive tagging editable" data-no-duplicate="true" data-pre-tags-separator="," data-no-duplicate-text="Duplicate tags" data-type-zone-class="type-zone" data-case-sensitive="true" data-tag-box-class="tagging">
                                    {
                                      employeeData?.team_members?.map((teamMember) => {
                                        return (
                                          <div className="tag">
                                            <span>#</span>{teamMember.name}<input type="hidden" name="tag[]" value={teamMember.employee_id} />
                                            <a role="button" className="tag-i" key={teamMember.employee_id} onClick={(event) => { handleRemoveTeamMember(event, teamMember) }}>×</a>
                                          </div>
                                        )
                                      })
                                    }
                                    <input className="type-zone" contentEditable="true" /></div>
                                  <p className="text-muted">
                                    Click <code>x</code> to remove team members
                                  </p>
                                </div>

                                <div className="col-lg-6 form-group mb-3">
                                  <label htmlFor="selectTeamMember">Select team members</label>
                                  <select
                                    className="form-control"
                                    onChange={event => handleOnchangeTeamMemberWithoutSupervisor(event.target.value)}
                                    defaultValue="Select a team member"
                                  >
                                    <option value="Select a team member"></option>
                                    {
                                      getTeamMemberWithoutSupervisor()
                                    }
                                  </select>
                                </div>
                              </div>
                            </fieldset>
                          </> :
                          <></>
                      }

                      {
                        (!isSupervisor) ?
                          <div className="mb-3">
                            <label className='mb-3'>Activate supervisor role</label>
                            <FormSwitch text={'Supervisor role is disabled'} onChange={handleIsSupervisorSwitch} checked={false} />
                          </div> :
                          <></>
                      }
                    </>

                    <>
                      {
                        roles?.map((role) => (
                          <label className="checkbox checkbox-outline-primary">
                            <input 
                              type="checkbox" 
                              name={role.name} 
                              key={role.id} 
                              value={role.id} 
                              onChange={(e) => handleOnClickSetUserRoles(e.target.value)} 
                              checked={selectedRoles?.includes(role.id)}
                            />
                            <span>{uppercaseWord(role.name)}</span>
                            <span className="checkmark"></span>
                          </label>
                        ))
                      }
                    </>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-5">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Reset Password</div>
                    <div className="row row-xs">
                      <div className="col-md-5">
                        <input className="form-control" type="password" placeholder="Enter your password" autoComplete="off" onChange={event => setPasswordValue(prev => ({...prev, password: event.target.value}))} />
                      </div>
                      <div className="col-md-5 mt-3 mt-md-0">
                        <input className="form-control" type="password" placeholder="Confirm password" autoComplete="off" onChange={event => setPasswordValue(prev => ({...prev, password_confirmation: event.target.value}))} />
                      </div>
                      <div className="col-md-2 mt-3 mt-md-0">
                        <button className="btn btn-primary w-100" onClick={handlePasswordUpdate}>Update Password</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <button type="button" className="btn btn-primary btn-submit btn-md" onClick={handleOnUpdate} style={{ float: "right" }} >
                  Update
                </button>
              </div>
            </div>
            <Footer />
          </div>
      }

    </div>
  );
}
export default EmployeeEdit;
