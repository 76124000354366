import { useEffect, useState } from "react";
import DataTable from "../../components/tables/reactTable";
import Footer from "../../components/layout/Footer";
import RequestService from "../../services/RequestService";
import { useSelector } from "react-redux";
import getBadgeColorByStatus from "../../utils/badgeColor";
import Loader from "../../components/Loader";
import BreadCrumb from "../../components/layout/BreadCrumb";
import LeaveService from "../../services/LeaveService";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import getIconByFileURL from '../../utils/FileIcons';
import { uppercaseFirst, uppercaseWord } from "../../utils/helper";
import TTLCountdown from "../../components/TTLCountdown";

const LeaveHistory = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const requestService = RequestService();
  const { isRequestLoading = true, requestList = [] } = useSelector((state) => state.request);
  const leaveService = LeaveService();
  const [selectedLeave, setSelectedLeave] = useState({});
  const [showRequest, setShowRequest] = useState(false);

  useEffect(() => {
    requestService.clear();
    
    (async () => {
      await requestService.getRequestByEmployeeIdAndCategory( user?.data.employee.id, 1 );
    })();
  }, []);

  const columns = [
    {
      label: "S/N",
      render: (leave) => leave?.index,
      sortFunction: () => (item) => item?.index,
    },

    {
      label: "Policy",
      render: (leave) => `${leave?.request_name}`,
      sortFunction: () => (item) => item?.name,
    },
    {
      label: "Start Date",
      render: (leave) => { 
        return getRequestValue(leave?.request_value, 'Start date')
       },
      sortFunction: () => (item) => item?.name,
    },
    {
      label: "Resumption Date",
      render: (leave) => {return getRequestValue(leave?.request_value, 'Resumption date')},
      sortFunction: () => (item) => item?.name,
    },
    {
      label: "Duration",
      render: (leave) => {
        const startDate = getRequestValue(leave?.request_value, 'Start date');
        const resumptionDate = getRequestValue(leave?.request_value, 'Resumption date');
        if(startDate && resumptionDate){
          return leaveService.calculateDuration(startDate, resumptionDate);
        }
      },
      sortFunction: () => (item) => item?.name,
    },
    {
      label: "Status",
      render: (leave) => (
        <span className={`badge bg-${getBadgeColorByStatus(leave?.state_type_name)}`} >
          {uppercaseWord(leave?.state_name)}
        </span>
      ),
      sortFunction: () => (item) => item?.status,
    },
    {
      label: "Date/Time",
      render: (leave) => {
        const isoDate = leave?.created_at;
        const dateObj = new Date(isoDate);
        const formattedDate = `${dateObj.toLocaleString("en-US", {
          month: "long",
        })} ${dateObj.getDate()}, ${dateObj.getFullYear()}, ${dateObj.toLocaleTimeString(
          "en-US"
        )}`;
        return formattedDate;
      },
      sortFunction: () => (item) => new Date(item?.date),
    },
    {
      label: "Actions",
      render: (leave) => (
        <i className="text-20 i-Windows-2 text-primary" style={{ cursor: "pointer", marginRight: '10px' }} title="View" onClick={() => handleShowLeave(leave)}></i>
      ),
    },
  ];

  const getRequestValue = (requestValues, searchTerm) => {
    const values = (requestValues) ? JSON.parse(requestValues) : [];

    for (const key in values) {
      if (values[key].name === searchTerm) {
        return `${values[key].value}`;
      }
    }

    return '';
  }

  const handleShowLeave = (leave) => {
    setSelectedLeave(leave);
    setShowRequest(true);
  }

  const handleClose = () => {
    setShowRequest(false);
}

  return (
    <div className="main-content">
      <BreadCrumb
        title="Leave History"
        parentLink="#"
        parentTitle="Leave History"
        currentTitle="Leave Requests"
      />
      <div className="separator-breadcrumb border-top"></div>
      <div className="row"></div>
      {/* start of main-content */}

        <div className="row mb-4">
          <div className="col-md-12 mb-4">
          <div className="card text-start">
            <div className="card-body">
              <h5 className="card-title" style={{ marginTop: "10px" }}>
                Leave History
              </h5>
              {
                isRequestLoading ? <Loader /> : <DataTable data={ requestList } columns={columns} />
              }
            </div>
          </div>
          </div>
        </div>

      {/* end of main-content */}

      {/*** Show Request */}
      <Modal show={showRequest} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedLeave.request_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="card">
                            <div className="card-body">
                                <div className="ul-widget__body">
                                    <div className="tab-content">
                                        <div className="tab-pane active show" id="ul-widget5-tab1-content">
                                            <div className="ul-widget5">
                                                <div className="ul-widget5__item">
                                                    <div className="ul-widget5__content">
                                                        <div className="ul-widget5__section">
                                                            <a className="ul-widget4__title" href="#">Date Created</a>
                                                            <p className="ul-widget5__desc">
                                                                {selectedLeave.created_at}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (selectedLeave.request_value) &&
                                                    JSON.parse(selectedLeave.request_value)?.map((item) => {
                                                        if (item.attribute_type !== 'file') {
                                                            return (
                                                                <div className="ul-widget5__item">
                                                                    <div className="ul-widget5__content">
                                                                        <div className="ul-widget5__section">
                                                                            <a className="ul-widget4__title" href="#">{uppercaseWord(item.name)}</a>
                                                                            <p className="ul-widget5__desc">
                                                                                {
                                                                                    (!Array.isArray(item.value)) ? item.value : item?.value.map(name => (<span className="badge rounded-pill badge-outline-primary p-2 m-1">{name.name}</span>))
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        if (item.attribute_type === 'file') {
                                                            return item?.value?.map((file) => {
                                                                return (
                                                                    <div className="ul-widget5__item">
                                                                        <div className="ul-widget5__content">
                                                                            <div className="ul-widget5__section">
                                                                                <a className="ul-widget4__title" href="#">Supporting Documents</a>
                                                                                <p className="ul-widget5__desc">
                                                                                    <a href={file} target="_blank" rel="noopener noreferrer">
                                                                                        <img src={getIconByFileURL(file)} width="50" height="50" />
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    })
                                                }

                                                {
                                                  (selectedLeave?.state_name) && <span className={`badge bg-${getBadgeColorByStatus( selectedLeave.state_type_name )}`}> {uppercaseWord(selectedLeave?.state_name)} </span>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
      {/* Footer Start */}
      <TTLCountdown />
      <Footer />
      {/* fotter end */}
    </div>
  );
};

export default LeaveHistory;
