import { useState, useEffect } from "react";
import RequestService from "../../services/RequestService";
import { toast } from "react-toastify";
import BreadCrumb from "../../components/layout/BreadCrumb";
import Footer from "../../components/layout/Footer";
import Loader from "../../components/Loader";
import validationUtils from "../../components/validation/FormValidation";
import HolidayService from "../../services/HolidayService";
import { useSelector } from 'react-redux';
import LeaveAttributeBuilder from "../../components/request/LeaveAttributeBuilder";
import { serverUrl } from "../../utils/constants";
import axios from "axios";
import LeaveService from "../../services/LeaveService";
import { formatDate } from '../../utils/helper';
import { excerpt } from "../../utils/helper";
import TTLCountdown from "../../components/TTLCountdown";

const LeaveRequest = () => {

    const [leaveTypes, setLeaveTypes] = useState([]);
    const [selectedLeaveType, setSelectedLeaveType] = useState('');
    const requestService = RequestService();
    const [formData, setFormData] = useState(null);
    const [formValues, setFormValues] = useState({});
    const [validationErrors, setValidationErrors] = useState({});
    const [fileUploadedState, setFileUploadedState] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const user = JSON.parse(sessionStorage.getItem('user'));
    const accessToken = JSON.parse(sessionStorage.getItem("token"));
    const holidayService = HolidayService();
    const { holidays = [] } = useSelector((state) => state.holiday);
    const [startDate, setStartDate] = useState(null);
    const [resumptionDate, setResumptionDate] = useState(null);
    const [leaveDuration, setLeaveDuration] = useState(0);
    const { leaves = [], leaveBalance = [] } = useSelector((state) => state.leave);
    const leaveService = LeaveService();
    const [windowZoom, setWindowZoom] = useState(1);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const response = await requestService.getRequestByName('leave');
            await leaveService.get();
            await holidayService.get();
            await leaveService.getLeaveBalance(user?.data.employee.id);
            setLeaveTypes(response);
            setIsLoading(false);
        })();

        setWindowZoom(window.outerWidth / window.innerWidth);
    }, []);

    useEffect(() => {
        setLeaveDuration(leaveService.calculateDuration(startDate, resumptionDate));
    }, [startDate, resumptionDate, leaveDuration]);

    const handleLeaveTypeChange = async (event) => {
        setIsLoading(true);
        setStartDate(null);
        setResumptionDate(null);
        setLeaveDuration(0);
        setFormValues({});
        const selectedType = event.target.value;
        setSelectedLeaveType(selectedType);
        const response = await requestService.getRequestForm(selectedType);
        setFormData(response);
        setIsLoading(false);
    };

    const handleInputChange = (fieldName, selectedOption) => {
        setFormValues((prev) => ({ ...prev, [fieldName]: selectedOption }));
        setValidationErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '', }));
    }

    const handleStartDateChange = (fieldName, selectedOption) => {
        setStartDate(selectedOption);
        setFormValues((prev) => ({ ...prev, [fieldName]: formatDate(selectedOption) }));
        setValidationErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '', }));
    }

    const handleResumptionDateChange = (fieldName, selectedOption) => {
        setResumptionDate(selectedOption);
        setFormValues((prev) => ({ ...prev, [fieldName]: formatDate(selectedOption) }));
        setValidationErrors((prevErrors) => ({ ...prevErrors, [fieldName]: '', }));
    }

    const handleFilesChange = (fieldName, selectedFiles) => {
        setUploadedFiles((prevUploadedFiles) => ({ ...prevUploadedFiles, [fieldName]: selectedFiles, }));
        setFileUploadedState((prevState) => ({ ...prevState, [fieldName]: selectedFiles.length > 0, }));
    };

    const handleSubmit = async () => {
        setIsLoading(true);
        const validationConfig = requestService.validate(formData.attributes, fileUploadedState);
        const validationErrors = validationUtils.validateForm(formValues, validationConfig);

        if (Object.keys(validationErrors).length > 0 || leaveService.isResumptionDateGreaterThanStartDate(resumptionDate, startDate)) {
            setValidationErrors(validationErrors);
            setIsLoading(false);
            return;
        }

        try {
            const headers = { Authorization: `Bearer ${accessToken}`, };

            const formDataToSend = new FormData();

            formDataToSend.append('employee_id', user.data.employee.id);

            if (formData && formData.id) {
                formDataToSend.append('request_id', formData.id);
            }

            for (const fieldId of Object.keys(uploadedFiles)) {
                const files = uploadedFiles[fieldId];
                for (const file of files) {
                    formDataToSend.append(fieldId, file);
                }
            }

            for (const fieldId of Object.keys(formValues)) {
                const value = formValues[fieldId];
                if (Array.isArray(value)) {
                    formDataToSend.append(fieldId, value.map((item) => item.value).join(', '));
                }

                if (typeof value === 'object' && 'value' in value) {
                    formDataToSend.append(fieldId, value.value);
                }

                if (!Array.isArray(value) && !(typeof value === 'object' && 'value' in value)) {
                    formDataToSend.append(fieldId, value);
                }
            }

            const response = await axios.post(`${serverUrl}/requests`, formDataToSend, { headers });

            if (response.status === 200 || response.status === 201) {
                await leaveService.getLeaveBalance(user?.data.employee.id);
                setIsLoading(false);
                setStartDate(null);
                setResumptionDate(null);
                setLeaveDuration(0);
                setFormValues({});
                toast.success("Request saved successfully!");
            } else {
                setValidationErrors(validationErrors);
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error?.response.data.message);
        }
    }

    const getLeaveBalance = (leave) => {
        const balance = leaveBalance.filter(balance =>  (balance.leave_id === leave.id));
        if (balance.length === 0) return leave.allocation;

        return balance[0].leave_balance;
    }

    return (
        <div className="main-content">
            <BreadCrumb title="Leave" parentLink="#" parentTitle="Pages" currentTitle="Leave Request" />

            <div className="separator-breadcrumb border-top"></div>
            {/* Start of main content */}
            {
                (isLoading) ? <Loader /> :
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <div className="card-body">
                                            <div className="card-title m-0">Leave status</div>
                                            <p className="text-small text-muted">
                                                Your remaining leave days.
                                            </p>
                                            <div className="row">
                                                {
                                                    leaves?.map((leave) => {
                                                        if (
                                                            leave.name === 'Maternity Leave' && user?.data.gender.value === 1 ||
                                                            leave.name === 'Paternity Leave' && user?.data.gender.value === 0
                                                        ) {
                                                            return;
                                                        }
                                        
                                                        return (
                                                            <div className="col-lg-6 col-md-12 mb-4" key={leave.id} data-html="true" data-bs-toggle="tooltip" data-bs-placement="left" title={leave.name} data-original-title={leave.name}>
                                                                <div className="p-4 border border-light rounded d-flex align-items-center">
                                                                    <i className={`${leave.icon} text-18 me-3`}></i>
                                                                    <div>
                                                                        <p className="text-muted mt-2 mb-2">{leave.name}</p>
                                                                        <span>{`Balance:`} {user?.data?.employee?.employment_information?.is_on_probation ? 0 : getLeaveBalance(leave)} Days</span>                                                                        
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            
                            !user?.data?.employee?.employment_information?.is_on_probation ?
                            (<div className="col-lg-6 col-md-12">
                                <div className="card mb-4">
                                    <div className="card-body p-0">
                                        <h5 className="card-title m-0 p-3">Apply for Leave</h5>
                                        <div className="p-3">
                                            <label htmlFor="leaveType" className="form-label">
                                                Select Leave Type:
                                            </label>
                                            <select
                                                id="leaveType"
                                                className="form-select"
                                                value={selectedLeaveType}
                                                onChange={handleLeaveTypeChange}
                                            >
                                                <option value="" disabled>Select Leave Type</option>
                                                {leaveTypes?.map((leaveType) => (
                                                    <option key={leaveType.id} value={leaveType.id}>
                                                        {leaveType.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-body">
                                        <form encType="multipart/form-data">
                                            <div className="row">
                                                <div className="card-title mb-3">{formData?.name}</div>
                                                {
                                                    formData?.attributes?.map((field) => (
                                                        <div className="col-lg-12 col-md col-sm form-group mb-3 mt-10" key={field.id}>
                                                            <label htmlFor={`attribute_id_${field.id}`}>
                                                                {field.name}{" "} {field.pivot?.is_required && (<span className="text-danger">*</span>)}
                                                            </label>
                                                            <LeaveAttributeBuilder
                                                                field={field}
                                                                formValues={formValues}
                                                                validationErrors={validationErrors}
                                                                handleInputChange={handleInputChange}
                                                                handleFilesChange={handleFilesChange}
                                                                fileUploadedState={fileUploadedState}
                                                                startDate={startDate}
                                                                resumptionDate={resumptionDate}
                                                                holidays={holidays?.map((holiday) => { return { date: holiday.date, holidayName: holiday.name } })}
                                                                handleStartDateChange={handleStartDateChange}
                                                                handleResumptionDateChange={handleResumptionDateChange}
                                                                leaveDuration={leaveDuration}
                                                            />
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            {
                                                selectedLeaveType !== '' ?
                                                    <div className="col-md-12">
                                                        <button className="btn btn-primary m-1" type="button" style={{ float: "right" }} onClick={handleSubmit}>Submit</button>
                                                    </div> :
                                                    <></>
                                            }

                                        </form>
                                    </div>
                                </div>
                            </div>) :
                                (<div className="col-lg-6 col-md-12">
                                    <div class="alert alert-dismissible fade show alert-danger" role="alert">
                                        <strong class="text-capitalize">You are still on probation</strong>
                                        <button class="btn btn-close" type="button" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                </div>)
                        }

                        

                    </div>
            }

            {/* end of main-content */}
            {/* Footer Start */}
            <TTLCountdown />
            <Footer />
            {/* footer end */}
        </div>
    );
};

export default LeaveRequest;