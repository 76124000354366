import React from 'react';
import { uppercaseWord } from '../../utils/helper';

const SectionA = ({ selectedEmployee, configurations, markSectionAItemAsSigned }) => {

    const getSectionAItems = (data) => {
        return data?.items.map((item, i) => {
            return (
                <tr key={i}>
                    <td>{item.name}</td>
                    <td>{(data?.representative != null) ? data?.representative?.full_name : uppercaseWord(selectedEmployee?.supervisor_full_name)}</td>
                    <td>
                        <label className="checkbox checkbox-outline-primary">
                            <input type="checkbox" checked={item?.is_cleared} onChange={() => markSectionAItemAsSigned(data, i)}/>
                            <span className="checkmark"></span>
                        </label>
                    </td>
                    <td>{item?.value}</td>
                </tr>
            );
        });
    };

    return (
        <div>
            <div className="row">
                <p>Department: {selectedEmployee?.department_name}</p>
                <p>Position: {selectedEmployee?.designation_name}</p>
                <p>Last Day Of Work</p>
            </div>

            <div className="table-responsive">
                <table className="table table-bordered" border="1" cellSpacing="0" cellPadding="5">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Name of Dept representative</th>
                            <th>Signature</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            configurations?.section_a?.data && configurations?.section_a?.data?.map((data, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td className="table-active" colSpan="4"><strong>{data?.description}</strong></td>
                                    </tr>
                                    {getSectionAItems(data)}
                                </React.Fragment>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SectionA;