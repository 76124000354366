import { useState } from "react";
import { Link } from "react-router-dom";

const SidebarMainMenu = ({ icon, name, display, link, handleMenuHover }) => {
  const [isActiveMainMenu, setIsActiveMainMenu] = useState(false);
  const handleOnMouseLeave = () => {
    //show the sub menu on mouse over
    setIsActiveMainMenu(false);
  };

  const handleOnMouseEnter = (e) => {
    handleMenuHover(name);
    setIsActiveMainMenu(true);
  };
  return (
    <li
      className={`nav-item ${isActiveMainMenu ? "active" : ""}`}
      data-item="dashboard"
      name={name}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <Link className="nav-item-hold" to={link}>
          <i className={icon} style={{ color: "#fff" }}></i>
        <span className="nav-text" style={{ color: " #9ca3af" }}>
          {display}
        </span>
      </Link>

      <div className="triangle"></div>
    </li>
  );
};

export default SidebarMainMenu;
