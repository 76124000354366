import BreadCrumb from "../components/layout/BreadCrumb";
import Footer from "../components/layout/Footer";
import { useEffect } from "react";
import AxiosService from "../services/AxiosService";
import { serverUrl } from "../utils/constants";

const ExitingEmployees = () => {

    useEffect(() => {
        const response = AxiosService.get(`${serverUrl}/exiting-employees`);
        console.log(response);
    }, []);

    return (
        <div className="main-content">
            <BreadCrumb
                title="Exiting Employees"
                parentLink="#"
                parentTitle="Pages"
                currentTitle="Exiting"
            />
            <div className="separator-breadcrumb border-top"></div>
            <div className="row"></div>

            {/* Footer Start */}
            <Footer />
            {/* fotter end */}
        </div>
    );
};

export default ExitingEmployees;