import BreadCrumb from "../components/layout/BreadCrumb";
import Footer from "../components/layout/Footer";
import { useEffect, useState } from "react";
import AxiosService from "../services/AxiosService";
//import { serverUrl } from "../utils/constants";
import Loader from "../components/Loader";
import { uppercaseFirst, uppercaseWord } from '../utils/helper';
import { toast } from "react-toastify";
import axio from 'axios';

const TimeManagement = () => {
    const [loading, setLoading] = useState(false);
    let token = '';
    const [transactions, setTransactions] = useState([]);
    const serverUrl = 'http://154.113.165.101:8085'

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const tokenResponse = await axio.post(`${serverUrl}/jwt-api-token-auth/`, {
                    'username': 'kp-solutions',
                    'password': 'kPyH:B!rXc4sH2:'
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Referer': 'http://154.113.165.101:8085'
                    }
                });
                if (tokenResponse.status === 200) {
                    token = tokenResponse.data.token;
                } else {
                    console.log(tokenResponse);
                    toast.error('Error fetching token');
                }
            } catch (error) {
                toast.error('Error fetching token');
            }
        })();

        // const currentDate = new Date().toISOString().split('T')[0];
        // const response = await AxiosService.get(`http://154.113.165.101:8085/iclock/api/transactions/?start_time=${currentDate}&page_size=200`, {
        //     headers: {
        //     'Authorization': `JWT ${token}`,
        //     'Content-Type': 'application/json'
        //     }
        // });

        // setTransactions(response.data);
        setLoading(false);
    }, []);

    return (
        <div className="main-content">
            <BreadCrumb
                title="Time Management"
                parentLink="#"
                parentTitle="Pages"
                currentTitle="Time Management"
            />
            <div className="separator-breadcrumb border-top"></div>
            {console.log(transactions)}

            {/* Footer Start */}
            <Footer />
            {/* fotter end */}
        </div>
    );
};

export default TimeManagement;