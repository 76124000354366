import BreadCrumb from "../components/layout/BreadCrumb";
import Footer from "../components/layout/Footer";
import { useEffect, useState } from "react";
import AxiosService from "../services/AxiosService";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const TimeManagement = () => {
    const [loading, setLoading] = useState(false);
    const [transactions, setTransactions] = useState([]);
    const serverUrl = 'http://46.101.19.209:8080/api/v1/admin/time-management';

    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                const response = await AxiosService.post(serverUrl);

                if (response?.data?.length > 0) {
                    setTransactions(response.data);
                }
            } catch (error) {
                console.log(error);
                toast.error('Time Management Server might be down, please confirm from the administrator!');
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return (
        <>
            {
                loading ? <Loader /> :
                    <div className="main-content">
                        <BreadCrumb
                            title="Time Management"
                            parentLink="#"
                            parentTitle="Pages"
                            currentTitle="Time Management"
                        />
                        <div className="separator-breadcrumb border-top"></div>
                        {console.log(transactions)}
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card text-left">
                                    <div className="card-body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Position</th>
                                                        <th>Location</th>
                                                        <th>Check In Time</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        transactions.map((transaction, index) => (
                                                            <tr key={index}>
                                                                <td>{transaction?.id}</td>
                                                                <td>{transaction?.first_name}</td>
                                                                <td>{transaction?.last_name}</td>
                                                                <td>{transaction?.position}</td>
                                                                <td>{transaction?.area_alias}</td>
                                                                <td>{transaction?.punch_time}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Footer Start */}
                        <Footer />
                        {/* fotter end */}
                    </div>
            }
        </>
    );
};

export default TimeManagement;