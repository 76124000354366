const Menus = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const isSupervisor = user?.data?.employee?.employment_information.is_supervisor;
  const roles = user?.data?.roles;
  const teamMembers = user?.data?.employee?.team_members;
  const isDesignationRequiredForApprovals = user?.data?.employee?.employment_information?.designation.is_required_for_approvals;

  const list = () => {
    return [
      {
        mainMenu: "dashboard",
        icon: "nav-icon i-Dashboard",
        display: "Dashboard",
        link: "#",
        subMenus: [
          {
            title: "Dashboard",
            link: "/dashboard",
            icon: "nav-icon i-Dashboard",
          },
        ],
      },
      {
        mainMenu: "leave",
        icon: "nav-icon i-Calendar",
        display: "Leave",
        link: "#",
        subMenus: [
          {
            title: "Apply for Leave",
            link: "/leave/request",
            icon: "nav-icon  i-Calendar-4",
          },
          { title: "Leave History", link: "/leave/history", icon: "nav-icon i-Clock-Back" },
          // { title: "Leave Plan", link: "#", icon: "nav-icon i-Calendar" },
          // { title: "Leave Plan History", link: "#", icon: "nav-icon i-Clock-Back" },
          // { title: "Leave Allowance", link: "#", icon: "nav-icon i-Coins" },
        ],
      },
      {
        mainMenu: "requisition",
        icon: "nav-icon i-Wallet",
        display: "Requisition",
        link: "#",
        subMenus: [
          {
            title: "My Requisitions",
            link: "/requisition/",
            icon: "nav-icon i-Male-21",
          },
          {
            title: "Expense Requisitions",
            link: "/requisition/expense",
            icon: "nav-icon i-Financial",
          },
          {
            title: "Service Requisitions",
            link: "/requisition/service",
            icon: "nav-icon i-Support",
          },
        ],
      },
      {
        mainMenu: "kras-appraisal",
        icon: "nav-icon i-Split-Horizontal-2-Window",
        display: "Appraisal",
        link: "#",
        subMenus: [
          {
            title: "Self Appraisal",
            link: "/appraisal",
            icon: "nav-icon i-Administrator",
          },
          (isSupervisor && teamMembers.length) &&
          {
            title: "Team Members",
            link: "/appraisal/team-members",
            icon: "nav-icon i-Business-Mens",
          },
          {
            title: "Appraisal Evaluation",
            link: "/appraisal/appraisal-evaluation",
            icon: "nav-icon i-Administrator",
          },
        ],
      },
      {
        mainMenu: "payslip",
        icon: "nav-icon i-Money-2",
        display: "Payslip",
        link: "#",
        subMenus: [
          ((hasRole('payroll admin')) && hasRole('payroll admin').length > 0) && {
            title: "Manage Payroll",
            link: "/payslip/payroll/manage",
            icon: "nav-icon i-Money-Bag",
          },
          {
            title: "Payslip",
            link: "/payslip",
            icon: "nav-icon i-Money-2",
          },
        ],
      },
      (isSupervisor || isDesignationRequiredForApprovals) && {
        mainMenu: "approvals",
        icon: "nav-icon i-Check",
        display: "Approvals",
        subMenus: [
          isSupervisor && {
            title: "Leave Management",
            link: "/leave/manage",
            icon: "nav-icon i-Checked-User",
          },
          {
            title: "Requisition Management",
            link: "/requisition/manage",
            icon: "nav-icon i-Checked-User",
          },
        ],
      },
      // (isSupervisor) && {
      //   mainMenu: "exiting",
      //   icon: "nav-icon i-Arrow-Out-Left",
      //   display: "Exiting Employees",
      //   subMenus: [
      //     isSupervisor && {
      //       title: "View",
      //       link: "/exit/manage",
      //       icon: "nav-icon i-Arrow-Out-Left",
      //     },
      //   ],
      // },
      ((hasRole('hr')) && hasRole('hr').length > 0 || (hasRole('admin')) && hasRole('admin').length > 0) && {
        mainMenu: "settings",
        icon: "nav-icon i-Gear",
        display: "Settings",
        link: "/settings",
        subMenus: [
          {
            title: "Appraisals",
            link: "/settings/appraisal/setup",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Appraisal Evaluation",
            link: "/settings/appraisal/evaluation",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Cardinals",
            link: "/settings/appraisal/cardinal/setup",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Key Responsibility Area",
            link: "/settings/appraisal/key-responsibility-area/setup",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Key Performance Indices",
            link: "/settings/appraisal/key-performance-index/setup",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Onboarding",
            link: "/settings/employee/list",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Holiday",
            link: "/settings/holiday",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Requests",
            link: "/settings/requisition/list",
            icon: "nav-icon i-Gear",
          },
          {
            title: "Request Forms",
            link: "/settings/request/input",
            icon: "nav-icon i-Gear",
          }
        ],
      },
    ];
  }

  const hasRole = (roleName) => {
    return roles?.filter((role) => role.name === roleName);
  }

  return {
    list,
  }
}

export default Menus;