import React from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "../../assets/css/custom/datepicker-styles.css";
import 'react-datepicker/dist/react-datepicker.css';
import CustomDropzone from "./CustomDropZone";

const FieldElements = 
(
  { 
    field, 
    formValues, 
    validationErrors, 
    handleInputChange, 
    handleFilesChange, 
    fileUploadedState, 
    startDate, 
    endDate, 
    initialInput = [],
    disabled = '',
  }
) => {

  const _getInputValue = (fieldHtmlName) => {
    if (formValues[fieldHtmlName]) return formValues[fieldHtmlName];

    if (initialInput[fieldHtmlName]) return initialInput[fieldHtmlName];

    return '';
  }

  const _getValidationError = (fieldHtmlName) => {
    return (validationErrors[fieldHtmlName]) ? <div className="text-danger">{validationErrors[fieldHtmlName]}</div> : <></>
  }

  const _getValidationErrorForFiles = (fieldHtmlName) => {
    return (validationErrors[fieldHtmlName] && (!fileUploadedState[fieldHtmlName])) ? <div className="text-danger">{validationErrors[fieldHtmlName]}</div> : <></>
  }

  const _getTextField = (fieldType) => {
    const fieldHtmlName = field.html_name;
    const fieldId = field.id;

    if (fieldType === 'text') {
      return (
        <>
          <input
            type="text"
            name={fieldHtmlName}
            value={_getInputValue(fieldHtmlName)}
            onChange={(e) => handleInputChange(fieldHtmlName, e.target.value)}
            className="form-control"
            key={fieldId}
            disabled = {disabled}
          />
          { _getValidationError(fieldHtmlName) }
        </>
      );
    }
  }

  const _getTextArea = (fieldType) => {
    const fieldHtmlName = field.html_name;
    const fieldId = field.id;

    if (fieldType === "large_text"){
      return (
        <>
          <textarea
            rows="6"
            placeholder={field.name}
            name={fieldHtmlName}
            value={_getInputValue(fieldHtmlName)}
            onChange={(e) => handleInputChange(fieldHtmlName, e.target.value)}
            className="form-control"
            key={fieldId}
            disabled = {disabled}
          >
            {_getInputValue(fieldHtmlName)}
          </textarea>
          { _getValidationError(fieldHtmlName) }
        </>
      )
    }
  }

  const _getNumber = (fieldType) => {
    const fieldHtmlName = field.html_name;
    const fieldId = field.id;

    if (fieldType === 'number') {
      return (
        <>
          <input
            type="number"
            name={fieldHtmlName}
            value={_getInputValue(fieldHtmlName)}
            onChange={(e) => handleInputChange(fieldHtmlName, e.target.value)}
            className="form-control"
            key={fieldId}
            disabled={disabled}
          />
          {_getValidationError(fieldHtmlName)}
        </>
      )
    }
  }

  const _getDropDown = (fieldType, isMulti = false) => {
    const fieldHtmlName = field.html_name;
    const fieldId = field.id;
    const fieldName = field.name;

    const attributeValues = field.attribute_values || [];
    const options = attributeValues.map((attributeValue) => (
      {
        value: attributeValue.id,
        label: attributeValue.value,
      }
    ));
    const selected = options.filter(option => {
      if (option.label === _getInputValue(fieldHtmlName)) return option;
    });

    if (isMulti === false && fieldType === 'drop_down') {
      return (
        <>
          <Select
            id={`${fieldHtmlName}${fieldId}`}
            value={selected[0]}
            name={fieldHtmlName}
            onChange={(selectedOption) => handleInputChange(fieldHtmlName, selectedOption)}
            required
            options={options}
            placeholder={`Select ${fieldName}`}
            isDisabled = {disabled}
          ></Select>

          {_getValidationError(fieldHtmlName)}
        </>
      )
    }

    if (isMulti === true && fieldType === 'drop_down') {
      return (
        <>
          <Select
            id={`${fieldHtmlName}${fieldId}`}
            value={selected[0]}
            name={fieldHtmlName}
            onChange={(selectedOption) => handleInputChange(fieldHtmlName, selectedOption)}
            required
            options={options}
            placeholder={`Select ${fieldName}`}
            isMulti={true}
            disabled = {disabled}
          ></Select>

          {_getValidationError(fieldHtmlName)}
        </>
      )
    }
  }

  const _getDateTime = (fieldType) => {
    const fieldHtmlName = field.html_name;

    if (fieldType === 'date_time') {
      return (
        <>
          <DatePicker
            selected={_getInputValue(fieldHtmlName) ? new Date(_getInputValue(fieldHtmlName)) : _getInputValue(fieldHtmlName)}
            onChange={(date) => handleInputChange(fieldHtmlName, date)}
            showTimeSelect
            timeIntervals={15}
            timeFormat="HH:mm"
            dateFormat="MMMM d, yyyy h:mm aa"
            className="form-control"
            name={fieldHtmlName}
            isClearable
            placeholderText="Please select a date and time"
            closeOnScroll={true}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
            dropdownMode="select"
            disabled = {disabled}
          />
          {_getValidationError(fieldHtmlName)}
        </>
      )
    }
  }

  const _getDate = () => {
    const fieldHtmlName = field.html_name;

    return (
      <>
        <DatePicker
            selected={_getInputValue(fieldHtmlName) ? new Date(_getInputValue(fieldHtmlName)) : _getInputValue(fieldHtmlName)}
            onChange={(date) => handleInputChange(fieldHtmlName, date)}
            dateFormat="MMMM d, yyyy"
            className="form-control"
            name={fieldHtmlName}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            isClearable
            placeholderText="Please select a date"
            closeOnScroll={true}
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
            dropdownMode="select"
            disabled = {disabled}
          />
          {_getValidationError(fieldHtmlName)}
      </>
    )
  }

  const _getTime = () => {
    const fieldHtmlName = field.html_name;

    return (
      <>
        <DatePicker
          selected={_getInputValue(fieldHtmlName) ? new Date(_getInputValue(fieldHtmlName)) : _getInputValue(fieldHtmlName)}
          onChange={(date) => handleInputChange(fieldHtmlName, date)}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          timeFormat="HH:mm"
          dateFormat="h:mm aa"
          className="form-control"
          name={fieldHtmlName}
          disabled = {disabled}
        />
        {_getValidationError(fieldHtmlName)}
      </>
    )
  }

  const _getFile = () => {
    const fieldHtmlName = field.html_name;
    const MAX_FILES = 5;
    const ALLOWED_FORMATS = [
      "image/png",
      "image/jpeg",
      "image/jpg",
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];
    const MAX_SIZE = 5242880; // 5MB

    return (
      <>
        <CustomDropzone
            onFilesChange={ (validFiles) => handleFilesChange(fieldHtmlName, validFiles) }
            maxFiles={MAX_FILES}
            allowedFormats={ALLOWED_FORMATS}
            maxSize={MAX_SIZE}
            name={fieldHtmlName}
            disabled = {disabled}
          />
          {_getValidationErrorForFiles(fieldHtmlName)}
      </>
    )
  }

  switch (field.type) {
    case "text":
      return (_getTextField(field.type));

    case "number":
      return (_getNumber(field.type));

    case "large_text":
      return (_getTextArea(field.type));

    case "drop_down":
      return (_getDropDown('drop_down', false));

    case "add_employee":
      return (_getDropDown('drop_down', true));

    case "date_time":
      return (_getDateTime(field.type));

    case "date":
      return (_getDate(field.type));

    case "time":
      return (_getTime(field.type));

    case "file":
      return (_getFile());

    default:
      return null;
  }
};

export default FieldElements;
