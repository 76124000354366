import { useState } from "react";
import BreadCrumb from "../../components/layout/BreadCrumb";
import Footer from "../../components/layout/Footer";
import AxiosService from "../../services/AxiosService";
import { serverUrl } from "../../utils/constants";
import Loader from "../../components/Loader";
import PayslipTable from "../../components/tables/PayslipTable";
import DatePicker from "react-datepicker";
import TTLCountdown from "../../components/TTLCountdown";

const Payslip = () => {
    const [payslips, setPayslips] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showPayslip, setShowPayslip] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState('disabled');

    const handleOnSubmit = async () => {
        try {
            setIsLoading(true);
            const response = await AxiosService.get(`${serverUrl}/payslips`, { start_date: startDate, end_date: endDate });
            setPayslips(response.data);
            setShowPayslip(true);

            setIsLoading(false);
        } catch (error) {}
    }

    const showTable = () => {
        if (isLoading) return <Loader />;

        if (showPayslip) return (<PayslipTable {...payslips} />);
    }

    const handleDateChange = ([newStartDate, newEndDate]) => {    
        setStartDate(newStartDate);            
        setEndDate(newEndDate);

        if (newStartDate !== null && newEndDate !== null) {
            setIsSubmitButtonDisabled('');            
            return;
        }

        setIsSubmitButtonDisabled('disabled');        
    }

    return (
        <div className="main-content">
            <BreadCrumb title="Payslip" parentLink="#" parentTitle="Pages" currentTitle="Monthly Payslip" />
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="card text-start">
                        <div className="card-body">
                            <p>Search for your payslip</p>
                            <div className="col-lg-6 mb-5">
                                <div className="row row-xs">
                                    <div className="col-md-5">
                                        <DatePicker
                                            className="form-control"
                                            dateFormat="yyyy/MM"
                                            selected={startDate}
                                            onChange={handleDateChange}
                                            showMonthYearPicker
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                        />
                                    </div>
                                    <div className="col-md-4 mt-3 mt-md-0">
                                        <button className="btn btn-primary w-100" disabled={isSubmitButtonDisabled} onClick={handleOnSubmit}>Search</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <>
                    { showTable() }
                </>
            </div>

            <TTLCountdown />
            <Footer />
        </div>
    );

}

export default Payslip;