import background from "../assets/images/jess-bailey-q10VITrVYUM-unsplash.jpg";
import logo from "../assets/images/Kenna Logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState, useContext } from "react";
import Loader from "../components/Loader";
import AuthContext from "../utils/context/AuthContext";
import AxiosService from "../services/AxiosService";
import { serverUrl } from "../utils/constants";

const Login = () => {
  const [name, setName] = useState("");
  const [pwd, setPwd] = useState("");
  const navigate = useNavigate();
  const [showPwdError, setShowPwdError] = useState(false);
  const [queryParameters] = useSearchParams();
  const {addToAuthData} = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);

  const onClickPasswordErrMsg = () => {
    setShowPwdError(!showPwdError);
  };

  const handleSubmit = async () => {
    if (name === '' || pwd === '') {
      setShowPwdError(true);
      return;
    }

    try {
      setIsLoading(true);
      const response = await AxiosService.post(`${serverUrl}/auth/login`, { username: name, password: pwd });
      const { access_token, user, ttl } = response;
      addToAuthData(user, access_token, ttl);
      setIsLoading(false);

      if (user) {
        (!queryParameters.get('redirect')) ? navigate("/dashboard") : navigate(queryParameters.get('redirect'));
      }
    } catch (error) {
      setIsLoading(false);
      setShowPwdError(true);
    }
  };

  if (isLoading) return (<Loader />); // <-- return early with loading message

  const handleChangeName = (event) => {
    setName(event.target.value.trim());
  }

  const handleChangePwd = (event) => {
    setPwd(event.target.value);
  };

  return (
    <>
      <div
        className="auth-layout-wrap"
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      >
        <div className="auth-content">


          <div className="col-md-12">
            <div className="card mb-4">
              <div className="card-body">
                <div className="auth-logo text-center mb-4">
                  <img src={logo} alt="" style={{ height: "90px", width: "150px" }} />
                  <h1 className="mb-3 text-18">Sign In</h1>
                </div>
                <>
                  <div className="row">
                    <div className="col-md-12 form-group mb-3">
                      <label htmlFor="username">Username</label>
                      <input
                        style={{ backgroundColor: "#F5EAFF", borderColor: "#731EC9", }}
                        className="form-control form-control-rounded"
                        name="username"
                        value={name}
                        onChange={(event) => handleChangeName(event)}
                        type="text"
                      />
                    </div>

                    <div className="col-md-12 form-group mb-3">
                      <label htmlFor="password">Password</label>
                      <input
                        style={{ backgroundColor: "#F5EAFF", borderColor: "#731EC9", }}
                        className="form-control form-control-rounded"
                        type="password"
                        name="pwd"
                        value={pwd}
                        onChange={(event) => handleChangePwd(event) }
                      />
                    </div>                    

                    <div className="col-md-12 form-group mb-3">
                      {showPwdError ? (
                        <div className="alert alert-dismissible fade show alert-card alert-danger" role="alert" >
                          <strong className="text-capitalize"> Password error! </strong>{" "}
                          Kindly check your username or password and try again
                          <button
                            className="btn btn-close"
                            type="button"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={onClickPasswordErrMsg}
                          ></button>
                        </div>
                      ) :
                        <></>
                      }
                    </div>
                    

                    <div className="col-md-12 form-group mb-3">
                      <input type="button" className="btn btn-rounded btn-primary w-100 mt-2" onClick={handleSubmit} value='Sign In' />
                    </div>

                  </div>
                </>
                <div className="mt-3 text-center">
                  <a className="text-muted" href="/forgot-password">
                    <u>Forgot Password?</u>
                  </a>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
    </>
  );
};

export default Login;
