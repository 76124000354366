import { useState, useEffect } from "react";
import Footer from "../layout/Footer";
import RequestTypeButton from "./RequestTypeButton";
import "../../assets/css/custom/requisition-styles.css";
import NoRecords from "./not-found";
import RequestService from "../../services/RequestService";
import { useSelector } from "react-redux";
import BreadCrumb from "../layout/BreadCrumb";
import Loader from "../Loader";
import TTLCountdown from "../TTLCountdown";

const NewRequestSelector = ({ category }) => {
const [searchTerm, setSearchTerm] = useState("");
const [expenseRequestTypes, setExpenseRequestTypes] = useState({});
const requestService = RequestService();
const { isRequestLoading = true, requestTypes = [] } = useSelector( (state) => state.request );

useEffect(() => {
  (async () => {
    const response = await requestService.getRequestByName('requisition');

    if (typeof response === 'undefined') {
      return setExpenseRequestTypes({});
    }

    const responseData = category === "Service" ? response?.Service : response?.Expense;
    setExpenseRequestTypes(responseData);
  })();

}, [category]);

const handleSearch = (event) => {
  const searchTerm = event.target.value.toLowerCase();
  setSearchTerm(searchTerm);
};

const filteredRequestTypes = Object.keys(expenseRequestTypes).filter((categoryName) => {
  const categoryItems = Object.values(expenseRequestTypes[categoryName]);
  return (
    categoryName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    categoryItems.some((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );
});

  const getRequestType = () => {
    if (filteredRequestTypes.length === 0) {
      return <NoRecords />
    }

    return Object.keys(expenseRequestTypes).map((categoryName) => {
      const categoryItems = Object.values(expenseRequestTypes[categoryName]);
      const filteredItems = categoryItems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (filteredItems.length > 0) {
        return (
          <div key={categoryName} className="card mb-4">
            <div className="card-body">
              <div className="m-0">{`${categoryName} Requests`}</div>
              <p className="text-small text-muted">
                {`${categoryName} request listings.`}
              </p>
              <div className="row">
                {filteredItems.map((item) => (
                  <RequestTypeButton
                    key={item.id}
                    id={item.id}
                    title={item.name}
                    // description={`Raise New Request`}
                    group={categoryName.toLowerCase()}
                  />
                ))}
              </div>
            </div>
          </div>
        );
      }
    })
  }

  return (
    <div className="main-content">
      <BreadCrumb
        title="Requisition"
        parentLink="#"
        parentTitle="Pages"
        currentTitle={`${category} Requisitions`}
      />

      <div className="separator-breadcrumb border-top"></div>
      <div className="row">
        {/* Start of main content */}
        <div className="col-md-12">
          <div className="row">
            <div className="search-bar input-with-icon" style={{ float: "right" }}>
            <input
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearch}
                className="rounded-input custom-icon-input"
              />
            </div>
          </div>
          <div id="groupContainer">
            <div className="card-title mb-3">List of Available {category} Requisition Policies</div>
            {
              isRequestLoading ? <Loader /> : getRequestType()
            }
          </div>
        </div>
      </div>
      {/* end of main-content */}
      {/* Footer Start */}
      <TTLCountdown />
      <Footer />
      {/* footer end */}
    </div>
  );
};

export default NewRequestSelector;
