import { useState } from "react";
import Footer from "../components/layout/Footer";
import BreadCrumb from "../components/layout/BreadCrumb";
import EmployeeService from "../services/EmployeeService";
import { useSelector } from "react-redux";
import Loader from "../components/Loader";
import { uppercaseFirst, uppercaseWord } from "../utils/helper";
import TTLCountdown from "../components/TTLCountdown";
import SectionA from "../components/exit_configuration/SectionA";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AxiosService from "../services/AxiosService";
import { serverUrl } from "../utils/constants";
import { toast } from "react-toastify";

function EmployeeProfile() {
  const employeeService = EmployeeService();
  const { loading = true } = useSelector((state) => state.employee);
  const [passwordValue, setPasswordValue] = useState({ password: '', password_confirmation: '' });
  let user = JSON.parse(sessionStorage.getItem("user"));
  const employeeId = user?.data?.employee.id;
  const [showExitForm, setShowExitForm] = useState(false);
  const configurations = JSON.parse(user?.data?.employee?.employment_information?.exit_form_configurations);
  const selectedEmployee = {
    id: user?.data?.employee?.id,
    supervisor_full_name: user?.data?.employee?.employment_information?.supervisor?.name,
    department_name: user?.data?.employee?.employment_information?.department?.name,
    designation_name: user?.data?.employee?.employment_information?.designation?.name,
  }
  const [isLoading, setIsLoading] = useState(false);

  const handlePasswordUpdate = async () => {
    await employeeService.passwordUpdate(employeeId, passwordValue);
  }

  const handleCloseExitForm = () => setShowExitForm(false);

  const handleShowExitForm = () => setShowExitForm(true);

  const markSectionAItemAsSigned = (data, index) => { return }

  const handleSignExitForm = () => {
    handleCloseExitForm();

    let { section_a } = configurations;
    if (section_a?.details[0]) {
      section_a.details[0].signed_on = new Date().toISOString();
    }

    configurations.section_a = section_a;

    if (user?.data?.employee) {
      user.data.employee.employment_information.exit_form_configurations = JSON.stringify(configurations);
      sessionStorage.setItem("user", JSON.stringify(user));
    }

    (async () => {
      try {
        setIsLoading(true);
        await AxiosService.patch(`${serverUrl}/update-employee-exit-form-configuration/employee/${selectedEmployee?.id}`, { configuration: configurations });
        setIsLoading(false);
      } catch (error) {
        toast.error(error?.message);
      }
    })();
  }

  const isExitFormSigned = () => {
    let { section_a } = configurations;

    if (section_a?.details[0]) {
      if (typeof section_a.details[0].signed_on !== 'undefined') {
        return true;
      }
    }

    return false;
  }

  return (
    <div className="main-content">
      <BreadCrumb
        title="Employee Profile"
        parentLink="#"
        parentTitle="Profile"
        currentTitle="Employee Profile"
      />
      <div className="separator-breadcrumb border-top"></div>

      {
        loading || isLoading ? <Loader /> :
          <div className="col-md-12">
            <div className="row">
              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Personal Details</div>
                    <form>
                      <div className="row">

                        <div className="form-group mb-3">
                          <label htmlFor="firstName1">First name</label>
                          <input
                            className="form-control"
                            id="firstName"
                            type="text"
                            disabled={true}
                            value={uppercaseFirst(user?.data?.first_name) ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Middle name</label>
                          <input
                            className="form-control"
                            id="middleNme"
                            type="text"
                            disabled={true}
                            value={uppercaseFirst(user?.data?.middle_name) ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="lastName1">Last name</label>
                          <input
                            className="form-control"
                            id="lastName"
                            type="text"
                            disabled={true}
                            value={uppercaseFirst(user?.data?.last_name) ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="dateOfBirth">Birth date</label>
                          <input
                            className="form-control"
                            id="dateOfBirth"
                            type="text"
                            disabled={true}
                            value={user?.data?.date_of_birth ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="gender">Gender</label>
                          <input
                            className="form-control"
                            id="gender"
                            type="text"
                            disabled={true}
                            value={uppercaseFirst(user?.data?.gender.sex) ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="marital_status">Marital Status</label>
                          <input
                            className="form-control"
                            id="marital_status"
                            type="text"
                            disabled={true}
                            value={uppercaseFirst(user?.data?.marital_status.status) ?? ""}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Employment Information</div>
                    <form>
                      <div className="row">
                        <div className="form-group mb-3">
                          <label htmlFor="hired_on">Hired On</label>
                          <input
                            className="form-control"
                            id="hired_on"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.employment_information.hired_on ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="department">Departmant</label>
                          <input
                            className="form-control"
                            id="department"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.employment_information.department.name ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="designation">Designation</label>
                          <input
                            className="form-control"
                            id="designation"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.employment_information.designation.name ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="access_type">Access Type</label>
                          <input
                            className="form-control"
                            id="access_type"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.employment_information.access_type.name ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="location">Location</label>
                          <input
                            className="form-control"
                            id="location"
                            type="text"
                            disabled={true}
                            value={uppercaseFirst(user?.data?.employee.employment_information.location.name) ?? ""}
                          />
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Address And Contact Informataion</div>
                    <form>
                      <div className="row">
                        <div className="form-group mb-3">
                          <label htmlFor="street">Street</label>
                          <input
                            className="form-control"
                            id="street"
                            type="text"
                            disabled={true}
                            value={uppercaseWord(user?.data?.employee.address_and_contact_information?.street) ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="city">City</label>
                          <input
                            className="form-control"
                            id="city"
                            type="text"
                            disabled={true}
                            value={uppercaseWord(user?.data?.employee.address_and_contact_information?.city) ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="state">State</label>
                          <input
                            className="form-control"
                            id="state"
                            type="text"
                            disabled={true}
                            value={uppercaseWord(user?.data?.employee.address_and_contact_information?.state) ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="zip_code">Zip Code</label>
                          <input
                            className="form-control"
                            id="zip_code"
                            type="text"
                            disabled={true}
                            value={uppercaseWord(user?.data?.employee.address_and_contact_information?.zip_code) ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="personal_email">Personal Email</label>
                          <input
                            className="form-control"
                            id="personal_email"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.address_and_contact_information?.personal_email ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="work_email">Work Email</label>
                          <input
                            className="form-control"
                            id="work_email"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.address_and_contact_information?.work_email ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">
                          <label htmlFor="mobile_phone">Mobile Phone</label>
                          <input
                            className="form-control"
                            id="mobile_phone"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.address_and_contact_information?.mobile_phone ?? ""}
                          />
                        </div>

                        <div className="col-md-6 form-group mb-3">?
                          <label htmlFor="work_phone">Work Phone</label>
                          <input
                            className="form-control"
                            id="work_phone"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.address_and_contact_information?.work_phone ?? ""}
                          />
                        </div>

                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Emergency Contact Information</div>
                    <form>
                      <div className="row">
                        <div className="form-group mb-3">
                          <label htmlFor="full_name">Full Name</label>
                          <input
                            className="form-control"
                            id="full_name"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.emergency_contact?.full_name ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="relationship">Relationship</label>
                          <input
                            className="form-control"
                            id="relationship"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.emergency_contact?.relationship ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="phone">Phone</label>
                          <input
                            className="form-control"
                            id="phone"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.emergency_contact?.phone ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="email">Email</label>
                          <input
                            className="form-control"
                            id="email"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.emergency_contact?.email ?? ""}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <label htmlFor="address">Address</label>
                          <input
                            className="form-control"
                            id="address"
                            type="text"
                            disabled={true}
                            value={user?.data?.employee.emergency_contact?.address ?? ""}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="card mb-4">
                  <div className="card-body">
                    <div className="card-title mb-3">Reset Password</div>
                    <div className="row row-xs">
                      <div className="col-md-5">
                        <input className="form-control" type="password" placeholder="Enter your password" autoComplete="off" onChange={event => setPasswordValue(prev => ({ ...prev, password: event.target.value }))} />
                      </div>
                      <div className="col-md-5 mt-3 mt-md-0">
                        <input className="form-control" type="password" placeholder="Confirm password" autoComplete="off" onChange={event => setPasswordValue(prev => ({ ...prev, password_confirmation: event.target.value }))} />
                      </div>
                      <div className="col-md-2 mt-3 mt-md-0">
                        <button className="btn btn-primary w-100" onClick={handlePasswordUpdate}>Update Password</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {
                (configurations !== null) ?
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <div className="card-body">
                        <div className="card-title mb-3">Sign Exit Form</div>
                        <div className="row row-xs">
                          <div className="col-md-2 mt-3 mt-md-0">
                            <button className="btn btn-primary w-100" onClick={handleShowExitForm}>View</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal show={showExitForm} onHide={handleCloseExitForm} keyboard={false} size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>Exit Form</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <SectionA selectedEmployee={selectedEmployee} configurations={configurations} markSectionAItemAsSigned={markSectionAItemAsSigned} />
                      </Modal.Body>
                      <Modal.Footer>
                        {
                          (isExitFormSigned()) ? <Button variant="primary" disabled> Signed </Button> : <Button variant="primary" onClick={handleSignExitForm}> Sign </Button>
                        }

                        <Button variant="secondary" onClick={handleCloseExitForm}> Close </Button>
                      </Modal.Footer>
                    </Modal>
                  </div> :
                  <></>
              }
            </div>

            <TTLCountdown />
            <Footer />
          </div>
      }

    </div>
  );
}

export default EmployeeProfile;