import React, {useEffect} from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "../../assets/css/custom/datepicker-styles.css";
import 'react-datepicker/dist/react-datepicker.css'
import CustomDropzone from "../requisition/CustomDropZone";
import { uppercaseWord } from "../../utils/helper";

const LeaveAttributeBuilder = ({
  field,
  formValues,
  validationErrors,
  handleInputChange,
  handleStartDateChange,
  handleResumptionDateChange,
  handleFilesChange,
  fileUploadedState,
  startDate,
  resumptionDate,
  holidays = [],
  leaveDuration = 0,
}) => {
  const fieldName = field.html_name;
  const inputValue = formValues[fieldName] || "";
  const MAX_FILES = 5;
  const ALLOWED_FORMATS = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];
  const MAX_SIZE = 1024 * 1024; // 1MB

  switch (field.type) {
    case "large_text":
      return (
        <div>
          <textarea
            rows="6"
            placeholder={field.name}
            name={fieldName}
            value={inputValue}
            onChange={(e) => handleInputChange(fieldName, e.target.value)}
            className="form-control"
            key={field.id}
          ></textarea>
          {validationErrors[fieldName] && (
            <div className="text-danger">{validationErrors[fieldName]}</div>
          )}
        </div>
      );

    case "add_employee":
      return (
        <div>
          <Select
            id={`${fieldName}${field.id}`}
            value={inputValue}
            name={fieldName}
            onChange={(selectedOption) =>
              handleInputChange(fieldName, selectedOption)
            }
            required
            options={field.attribute_values.map((value) => ({
              value: value.id,
              label: uppercaseWord(value.value),
            }))}
            placeholder={`Select ${field.name}`}
            isMulti={true}
          />
          {validationErrors[fieldName] && (
            <div className="text-danger">{validationErrors[fieldName]}</div>
          )}
        </div>
      );

    case "file":
      return (
        <div>
          <CustomDropzone
            onFilesChange={(validFiles) =>
              handleFilesChange(fieldName, validFiles)
            }
            maxFiles={MAX_FILES}
            allowedFormats={ALLOWED_FORMATS}
            maxSize={MAX_SIZE}
            name={fieldName}
          />
          {validationErrors[fieldName] && !fileUploadedState[fieldName] && (
            <div className="text-danger">{validationErrors[fieldName]}</div>
          )}
        </div>
      );

    case "leave_start_date":
      return (
        <div>
          <DatePicker
            selected={(inputValue === "" ? inputValue : new Date(inputValue))}
            onChange={(date) => handleStartDateChange(fieldName, date)}
            dateFormat="yyyy-MM-dd"
            className="form-control"
            name={fieldName}
            selectsStart
            startDate={startDate}
            endDate={resumptionDate}
            isClearable
            placeholderText="Please select a date"
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
            dropdownMode="select"
            holidays={holidays}
            autoComplete="off"
            excludeDates={holidays?.map((holiday) => new Date(holiday.date))}
            minDate={new Date()}
            filterDate={date => { return date.getDay() !== 0 && date.getDay() !== 6; }}
          />
          {validationErrors[fieldName] && (
            <div className="text-danger">{validationErrors[fieldName]}</div>
          )}
        </div>
      );

    case "leave_resumption_date":
      return (
        <div>
          <DatePicker
            selected={(inputValue === "" ? inputValue : new Date(inputValue))}
            onChange={(date) => handleResumptionDateChange(fieldName, date)}
            dateFormat="yyyy-MM-dd"
            className="form-control"
            name={fieldName}
            selectsStart
            startDate={startDate}
            endDate={resumptionDate}
            isClearable
            placeholderText="Please select a date"
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={50}
            dropdownMode="select"
            holidays={holidays}
            autoComplete="off"
            excludeDates={holidays?.map((holiday) => new Date(holiday.date))}
            minDate={new Date()}
            filterDate={date => { return date.getDay() !== 0 && date.getDay() !== 6; }}
          />
          {validationErrors[fieldName] && (
            <div className="text-danger">{validationErrors[fieldName]}</div>
          )}
        </div>
      );

    case "leave_duration":
      return (
        <div>
          <input
            type="number"
            name={fieldName}
            value={leaveDuration}
            className="form-control"
            key={field.id}
            disabled
          />
          {validationErrors[fieldName] && (
            <div className="text-danger">{validationErrors[fieldName]}</div>
          )}
        </div>
      );

    default:
      return null;
  }
};

export default LeaveAttributeBuilder;
