import { useEffect, useState } from 'react';
import BreadCrumb from "../../components/layout/BreadCrumb";
import DataTable from "../../components/tables/reactTable";
import { useSelector } from 'react-redux';
import WorkflowStateService from '../../services/WorkflowStateService';
import Loader from '../../components/Loader';
import FormSwitch from '../../components/form/formSwitch';
import Footer from '../../components/layout/Footer';
import RequestService from '../../services/RequestService';
import getBadgeColorByStatus from "../../utils/badgeColor";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import getIconByFileURL from '../../utils/FileIcons';
import TTLCountdown from '../../components/TTLCountdown';
import { uppercaseWord } from '../../utils/helper';

const LeaveManagement = () => {
    const user = JSON.parse(sessionStorage.getItem("user"));
    const designation = user?.data?.employee?.employment_information?.designation;
    const { isWorkflowStateLoading = true, workflowStates = [] } = useSelector((state) => state.workflowState);
    const { isRequestLoading = true, requestList = [] } = useSelector((state) => state.request);
    const workflowStateService = WorkflowStateService();
    const [selected, setSelected] = useState('');
    const [isDesignationMode, setIsDesignationMode] = useState(false);
    const requestService = RequestService();
    const [selectedRequest, setSelectedRequest] = useState({});
    const [showRequest, setShowRequest] = useState(false);
    const [showApproveRequest, setShowApproveRequest] = useState(false);
    const [showDeclineRequest, setShowDeclineRequest] = useState(false);
    const [selectedState, setSelectedState] = useState({});

    useEffect(() => {
        requestService.clear();

        (async () => {            
            await workflowStateService.getStates();
            getCurrentStates();
        })();
    }, []);

    const getCurrentStates = () => {
        let states = workflowStates?.filter((workflowState) => workflowState.is_supervisor_required);

        if (states.length === 0 || isDesignationMode) {
            states = getDesignationStates();
        }

        return states;
    }

    const getTableRecords = () => {
        if (isWorkflowStateLoading || isRequestLoading) {
            return <Loader />
        }

        return <DataTable data={requestList} columns={columns} />
    }

    const handleSelectStateChange = (event) => {
        let stateId = event.target.value;
        setSelected(event.target.value);
        const stateSeleted = workflowStates?.filter(workflowState => workflowState.id === parseInt(stateId));
        setSelectedState(stateSeleted[0]);

        (async () => {
            await requestService.getEmployeeRequestForApproval(parseInt(stateId), 1);
        })();
    }

    const handleApprovalModeSwitch = () => {
        setSelected("");
        setIsDesignationMode(!isDesignationMode);
        requestService.clear();
    }

    const getDesignationStates = () => {
        return workflowStates?.filter((workflowState) => workflowState?.required_designation?.id === designation.id);
    }

    const columns = [
        {
            label: "S/N",
            accessor: "index",
            render: (request) => request.index,
            sortFunction: () => (item) => item.index,
        },
        {
            label: "Employee Name",
            accessor: "first_name",
            render: (request) => `${request.first_name} ${(request.middle_name === null) ? ' ' : request.middle_name} ${request.last_name}`,
            sortFunction: () => (item) => item.first_name,
        },
        {
            label: "Request Name",
            accessor: "request_name",
            render: (request) => `${request.request_name}`,
            sortFunction: () => (item) => item.request_type_name,
        },
        {
            label: "Request Type",
            accessor: "request_type",
            render: (request) => `${request.request_type_name}`,
            sortFunction: () => (item) => item.request_type_name,
        },
        {
            label: "Status",
            accessor: "state_type_name",
            render: (request) => (
                <span className={`badge bg-${getBadgeColorByStatus(request.current_state_type_name)}`}>
                    {request.current_state_name}
                </span>
            ),
            sortFunction: () => (item) => item.status,
        },
        {
            label: "Date Created",
            accessor: "date_created",
            render: (request) => {
                const isoDate = request.date_created;
                const dateObj = new Date(isoDate);
                const formattedDate = `${dateObj.toLocaleString("en-US", {
                    month: "long",
                })} ${dateObj.getDate()}, ${dateObj.getFullYear()}, ${dateObj.toLocaleTimeString(
                    "en-US"
                )}`;
                return formattedDate;
            },
            sortFunction: () => (item) => new Date(item.date),
        },
        {
            label: "Actions",
            accessor: "actions",
            render: (request) => (
                <>
                    <i className="text-20 i-Windows-2 text-primary" style={{ cursor: "pointer", marginRight: '10px' }} title="View" onClick={() => handleShowRequest(request)}></i>
                    {
                        (selectedState?.state_type?.name === 'pending') &&
                        <>
                            <i className="text-20 i-Yes text-success" style={{ cursor: "pointer", marginRight: '10px' }} title="Approve" onClick={() => handleShowApproveRequest(request)}></i>
                            <i className="text-20 i-Remove text-danger" style={{ cursor: "pointer" }} title="Decline" onClick={() => handleShowDeclineRequest(request)}></i>
                        </>
                    }
                </>
            ),
        },
    ];

    const handleShowRequest = (request) => {
        setSelectedRequest(request);
        setShowRequest(true);
    };

    const handleClose = () => {
        setShowRequest(false);
        setShowApproveRequest(false);
        setShowDeclineRequest(false);
    }

    const handleShowApproveRequest = (request) => {
        setShowApproveRequest(true);
        setSelectedRequest(request);
    };

    const handleShowDeclineRequest = (request) => {
        setShowDeclineRequest(true);
        setSelectedRequest(request);
    };

    const handleApprove = async (stateTypeName) => {
        const approvalState = getCurrentStates()?.filter(currentState => currentState.state_type.name === stateTypeName);
        await requestService.updateEmployeeRequest(selectedRequest.employee_request_id, approvalState[0]?.id);
        requestService.removeItemFromRequests(selectedRequest);
        handleClose();
    }

    return (
        <div className="main-content">
            <BreadCrumb title="Leave" parentLink="#" parentTitle="Leave Management" currentTitle="Leave" />
            <div className="separator-breadcrumb border-top"></div>

            <div className="row">
                <div className="col-md-12">
                    <div className="card o-hidden text-start">
                        <div className="card-header d-flex align-items-center border-0">
                            <h3 className="w-50 float-start card-title m-0">Manage Leave</h3>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3 form-group mb-3">
                                    <label htmlFor="picker1">Select</label>
                                    <select className="form-control" value={selected} onChange={handleSelectStateChange}>
                                        <option disabled value="">Select an option</option>
                                        {
                                            getCurrentStates()?.map((currentState) => (
                                                <option key={currentState.id} value={currentState.id}>{uppercaseWord(currentState.state_type.name)}</option>
                                            ))
                                        }
                                    </select>
                                </div>
                                <div className="col-md-3 form-group mb-3">
                                    <label htmlFor="switch">Select Approval Mode</label>
                                    <FormSwitch text={"Designation Mode"} onChange={handleApprovalModeSwitch} />
                                </div>
                            </div>
                            <div className="table-responsive">
                                {getTableRecords()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*** Show Request */}
            <Modal show={showRequest} onHide={handleClose} keyboard={false} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{selectedRequest.request_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <>
                        <div className="card">
                            <div className="card-body">
                                <div className="ul-widget__body">
                                    <div className="tab-content">
                                        <div className="tab-pane active show" id="ul-widget5-tab1-content">
                                            <div className="ul-widget5">
                                                <div className="ul-widget5__item">
                                                    <div className="ul-widget5__content">
                                                        <div className="ul-widget5__section">
                                                            <a className="ul-widget4__title" href="#">Employee Name</a>
                                                            <p className="ul-widget5__desc">
                                                                {`${selectedRequest.first_name} ${selectedRequest?.middle_name === null ? ' ' : selectedRequest.middle_name} ${selectedRequest.last_name}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ul-widget5__item">
                                                    <div className="ul-widget5__content">
                                                        <div className="ul-widget5__section">
                                                            <a className="ul-widget4__title" href="#">Date Created</a>
                                                            <p className="ul-widget5__desc">
                                                                {selectedRequest.date_created}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ul-widget5__item">
                                                    <div className="ul-widget5__content">
                                                        <div className="ul-widget5__section">
                                                            <a className="ul-widget4__title" href="#">Request Type</a>
                                                            <p className="ul-widget5__desc">
                                                                {`${selectedRequest.request_type_name}, ${selectedRequest.request_category_name}`}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (selectedRequest.value) &&
                                                    JSON.parse(selectedRequest.value)?.map((item) => {
                                                        if (item.attribute_type !== 'file') {
                                                            return (
                                                                <div className="ul-widget5__item">
                                                                    <div className="ul-widget5__content">
                                                                        <div className="ul-widget5__section">
                                                                            <a className="ul-widget4__title" href="#">{item.name}</a>
                                                                            <p className="ul-widget5__desc">
                                                                                {
                                                                                    (!Array.isArray(item.value)) ? item.value : item?.value.map(name => (<span className="badge rounded-pill badge-outline-primary p-2 m-1">{name.name}</span>))
                                                                                }
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        if (item.attribute_type === 'file') {
                                                            return item?.value?.map((file) => {
                                                                return (
                                                                    <div className="ul-widget5__item">
                                                                        <div className="ul-widget5__content">
                                                                            <div className="ul-widget5__section">
                                                                                <a className="ul-widget4__title" href="#">Supporting Documents</a>
                                                                                <p className="ul-widget5__desc">
                                                                                    <a href={file} target="_blank" rel="noopener noreferrer">
                                                                                        <img src={getIconByFileURL(file)} width="50" height="50" />
                                                                                    </a>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    })
                                                }

                                                {
                                                    (selectedRequest?.employee_request_previous_state) &&
                                                    <>{selectedRequest?.employee_request_previous_state} <i class="text-20 i-Right-3"></i> {selectedRequest.current_state_name}</>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    {
                        (selectedState?.state_type?.name === 'pending') &&
                        <>
                            <Button variant="success" className="text-white" onClick={() => handleShowApproveRequest(selectedRequest)}>Approve</Button>
                            <Button variant="danger" className="text-white" onClick={() => handleShowDeclineRequest(selectedRequest)}>Decline</Button>
                        </>
                    }

                </Modal.Footer>
            </Modal>

            {/*** Approve Request */}
            <Modal show={showApproveRequest} onHide={handleClose} keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Approve Request</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Are you sure you want to <strong>approve</strong> this request.</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="success" className="text-white" onClick={() => handleApprove('approved')}>Approve</Button>
                </Modal.Footer>
            </Modal>

            {/*** Decline Request */}
            <Modal show={showDeclineRequest} onHide={handleClose} keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Decline Request</Modal.Title>
                </Modal.Header>
                <Modal.Body><p>Are you sure you want to <strong>decline</strong> this request.</p></Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="danger" className="text-white" onClick={() => handleApprove('declined')}>Decline</Button>
                </Modal.Footer>
            </Modal>

            <TTLCountdown />
            <Footer />

        </div>
    );

}

export default LeaveManagement;