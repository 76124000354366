import axios from "axios";
import { serverUrl } from "../utils/constants";
import { setAuthData, isLoading, setError } from "../store/slices/authenticationSlice";
import { useDispatch } from 'react-redux';
import AxiosService from "./AxiosService";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const AuthenticationService = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const login = async (username, password) => {
        try {
            dispatch(isLoading(true));
            const response = await AxiosService.post(`${serverUrl}/auth/login`, { username, password });
            dispatch(setAuthData(response.data));
        } catch (error) {
            toast.error("Please check your username and password");
            dispatch(setError(error.message));
        }

    }

    const logout = async () => {
        try {
            const accessToken = JSON.parse(sessionStorage.getItem("token"));
            await axios.post(`${serverUrl}/auth/logout`, null, { headers: { 'Authorization': `Bearer ${accessToken}`, } });
            navigate("/");
            localStorage.clear();
            sessionStorage.clear();
        } catch (error) {
            localStorage.clear();
            sessionStorage.clear();
            navigate("/");
        }
    }

    const refreshToken = async () => {
        const response = await AxiosService.get(`${serverUrl}/auth/refresh-token`);
        const { access_token, user, ttl } = response;
        sessionStorage.setItem("user", JSON.stringify(user));
        sessionStorage.setItem("token", JSON.stringify(access_token));
        sessionStorage.setItem("authenticated", true);
        sessionStorage.setItem("ttl", JSON.stringify(ttl * 60));
    }

    return {
        login,
        logout,
        refreshToken,
    }
}

export default AuthenticationService;
