import React, { useEffect, useState } from "react";
import FormSwitch from "../../../components/form/formSwitch";
import DataTable from "../../../components/tables/reactTable";
import ImageData from "../../../components/employee/avatar";
import { useNavigate, Link } from "react-router-dom";
import Footer from "../../../components/layout/Footer";
import BreadCrumb from "../../../components/layout/BreadCrumb";
import Loader from "../../../components/Loader";
import EmployeeService from "../../../services/EmployeeService";
import { useSelector } from "react-redux";
import { uppercaseFirst } from "../../../utils/helper";

const EmployeeList = () => {
  const navigate = useNavigate();
  const [employees, setEmployees] = useState([]);
  const employeeService = EmployeeService();
  const { loading = true } = useSelector( (state) => state.employee );
  const [configurations, setConfigurations] = useState({
    employees: 0,
    males: 0,
    females: 0,
    exited: 0
  });

  const [isExitedEmployeeSwitched, setIsExitedEmployeeSwitched] = useState(false);
  const [current, setCurrent] = useState([]);

  const handleRowClick = async (employeeId) => {
    navigate("/settings/employee/edit", {state: {employeeId}});
  };

  useEffect(() => {
    (async () => {
      const response = await employeeService.get();
      const formattedResponse = response?.map((employee, index) => ({...employee, index: index + 1 }));
      const males = formattedResponse?.filter(employee => employee?.personal_details.gender === "1");
      const females = formattedResponse?.filter(employee => employee?.personal_details.gender === "0");
      const exitedEmployees = formattedResponse?.filter(employee => employee?.employment_information?.employment_status === 3);
      setEmployees(formattedResponse);
      setCurrent(formattedResponse);
      setConfigurations({
        employees: formattedResponse?.length,
        males: males?.length,
        females: females?.length,
        exited: exitedEmployees?.length,
      });

      return () => {
        employeeService.cleanUp();
      }
    })();
  }, []);

  const columns = [
    {
      label: "S/N",
      accessor: "index",
      render: (employee) => employee.index,
      sortFunction: () => (item) => item.index,
    },
    {
      label: "Photo",
      accessor: "photo",
      render: (employee) => (
        <img
          className="rounded-circle m-0 avatar-sm-table"
          src={employee.personal_details.photo || ImageData}
          alt="employee image"
        />
      ),
    },
    {
      label: "Employee ID",
      accessor: "username",
      render: (employee) => `${employee.personal_details.username}`,
      sortFunction: () => (item) => item.personal_details.username,
    },
    {
      label: "First Name",
      accessor: "first_name",
      render: (employee) => uppercaseFirst(employee.personal_details.first_name),
      sortFunction: () => (item) => item.personal_details.first_name,
    },
    {
      label: "Last Name",
      accessor: "last_name",
      render: (employee) => uppercaseFirst(employee.personal_details.last_name),
      sortFunction: () => (item) => item.personal_details.last_name,
    },
    {
      label: "Designation",
      accessor: "designation",
      render: (employee) => (
        <span className="badge bg-primary">
          {employee.employment_information.designation.name}
        </span>
      ),
      sortFunction: () => (item) =>
        item.employment_information.designation.name,
    },
    {
      label: "Hired Date",
      accessor: "hired_on",
      render: (employee) => employee.employment_information.hired_on
    }
  ];

  const handleViewExitedEmployees = () => {
    setIsExitedEmployeeSwitched(!isExitedEmployeeSwitched);
    getExitedEmployees();
  }

  const getExitedEmployees = () => {
    if (isExitedEmployeeSwitched) {
      setEmployees(current);
    } else {
      const exitedEmployees = employees.filter(employee => employee?.employment_information?.employment_status === 3);
      setEmployees(exitedEmployees);
    }
  }

  return (
    <div className="main-content">
      <BreadCrumb
        title="Employee List"
        parentLink="#"
        parentTitle="Employee Records"
        currentTitle="Employee List"
      />
      <div className="separator-breadcrumb border-top"></div>

      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
            <div className="card-body text-center">
              <i className="i-MaleFemale"></i>
              <div className="content">
                <p className="text-muted mt-2 mb-0">Employees</p>
                <p className="text-primary text-24 line-height-1 mb-2">{configurations.employees}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
            <div className="card-body text-center">
              <i className="i-Male-2"></i>
              <div className="content">
                <p className="text-muted mt-2 mb-0">Males</p>
                <p className="text-primary text-24 line-height-1 mb-2">{configurations.males}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
            <div className="card-body text-center">
              <i className="i-Female-2"></i>
              <div className="content">
                <p className="text-muted mt-2 mb-0">Females</p>
                <p className="text-primary text-24 line-height-1 mb-2">{configurations.females}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <div className="card card-icon-bg card-icon-bg-primary o-hidden mb-4">
            <div className="card-body text-center">
              <i className="i-Close"></i>
              <div className="content">
                <p className="text-muted mt-2 mb-0">Exited</p>
                <p className="text-primary text-24 line-height-1 mb-2">{configurations.exited}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 mb-4">
          <Link className="settings-menu" to="/settings/employee/create">
            <button className="btn btn-primary ripple m-1 btn-icon" type="button">
              <span className="ul-btn__icon">
                <i className="i-Add-User"></i>
              </span>
              <span className="ul-btn__text"> Add An Employee</span>
            </button>
          </Link>

          <div style={{ float: "right", marginRight: "15px" }}>
            <small>See Exited Staff</small>
            <FormSwitch text="Exited" onChange={handleViewExitedEmployees} />
          </div>
        </div>
      </div>

      {
        loading ? <Loader /> :
          <div className="card text-start">
            <div className="card-body">
              <DataTable data={employees} columns={columns} onRowClick={handleRowClick} />
            </div>
          </div>
      }
      
      <Footer />
    </div>
  );
};

export default EmployeeList;
