import BreadCrumb from "../components/layout/BreadCrumb";
import Footer from "../components/layout/Footer";
import { useEffect, useState } from "react";
import AxiosService from "../services/AxiosService";
import { serverUrl } from "../utils/constants";
import Loader from "../components/Loader";
import face1 from "../assets/images/faces/1.jpg";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { uppercaseFirst, uppercaseWord } from '../utils/helper';
import { toast } from "react-toastify";
import SectionA from "../components/exit_configuration/SectionA";

const ExitingEmployees = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [configurations, setConfigurations] = useState([]);
    const [existingEmployees, setExistingEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [show, setShow] = useState(false);
    const user = JSON.parse(sessionStorage.getItem("user"))?.data;
    const roles = user?.roles;

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const response = await AxiosService.get(`${serverUrl}/exit-form-configurations/active`);
                setConfigurations(response?.exit_form_configuration);
                setExistingEmployees(response?.exiting_employees);
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                toast.error(error?.message);
            }
        })();
    }, [AxiosService]);

    const handleViewExitingEmployee = (employee) => {
        setSelectedEmployee(employee);
        if (employee?.exit_form_configuration !== null) setConfigurations(JSON.parse(employee?.exit_form_configuration));
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        resetConfigurations();
    }

    // const getSectionAItems = (data) => {
    //     return data?.items.map((item, i) => {
    //         return (
    //             <tr key={i}>
    //                 <td>{item.name}</td>
    //                 <td>{(data?.representative != null) ? data?.representative?.full_name : uppercaseWord(selectedEmployee?.supervisor_full_name)}</td>
    //                 <td>
    //                     <label className="checkbox checkbox-outline-primary">
    //                         <input type="checkbox" checked={item?.is_cleared} onChange={() => markSectionAItemAsSigned(data, i)}/>
    //                         <span className="checkmark"></span>
    //                     </label>
    //                 </td>
    //                 <td>{item?.value}</td>
    //             </tr>
    //         );
    //     });
    // };

    const markSectionAItemAsSigned = (data, id) => {
        let {section_a} = configurations;
        let sectionA = section_a;

        sectionA?.data?.filter((list) => {
            if (list.description === data.description) {
                if (list?.items[id] && list?.representative?.id === user?.employee?.id) {
                    list = getIsCleared(list, id);
                }

                if (list?.items[id] && list?.representative == null && user?.employee?.id === selectedEmployee?.supervisor_id) {
                    list = getIsCleared(list, id);
                }
            }
        });

        setConfigurations((prev) => ({...prev, section_a: sectionA}));
    };

    const resetConfigurations = () => {
        let {section_a, section_b} = configurations;
        let sectionA = section_a;
        let sectionB = section_b;

        sectionA?.data?.map((list) => {
            list?.items.map((item) => {
                item.is_cleared = false;
                item.value = '';
            });
        });

        sectionB?.data?.map((list) => {
            list?.items.map((item) => {
                item.is_cleared = false;
                item.not_avalilable = false;
                item.value = '';
            });
        });

        setConfigurations((prev) => ({...prev, section_a: sectionA, section_b: sectionB}));
    };

    const getIsCleared = (list, id) => {
        if (list.items[id].not_avalilable === true) list.items[id].not_avalilable = false;
        list.items[id].is_cleared = !list.items[id].is_cleared;

        if (list.items[id].is_cleared) {
            list.items[id].value = new Date().toLocaleDateString();
        } else {
            list.items[id].value = '';
        }

        return list;
    };

    const setNotAvalilable = (list, id) => {
        if (list.items[id].is_cleared === true) list.items[id].is_cleared = false;
        list.items[id].not_avalilable = !list.items[id].not_avalilable;

        if (list.items[id].not_avalilable) {
            list.items[id].value = new Date().toLocaleDateString();
        } else {
            list.items[id].value = '';
        }
    }

    const markSectionBItemAsSigned = (data, id) => {
        let {section_b} = configurations;
        let sectionB = section_b;

        sectionB?.data?.map((list) => {
            if (list.description === data.description) {
                if (list?.items[id] && roles?.filter((role) => role.name === 'hr').length > 0) {
                    list = getIsCleared(list, id);
                }
            }
        });

        setConfigurations((prev) => ({...prev, section_b: sectionB}));
    };

    const markSectionBItemAsUnAvailable = (data, id) => {
        let {section_b} = configurations;
        let sectionB = section_b;

        sectionB?.data?.map((list) => {
            if (list.description === data.description) {
                if (list?.items[id] && roles?.filter((role) => role.name === 'hr').length > 0) {
                    list = setNotAvalilable(list, id);
                }
            }
        });

        setConfigurations((prev) => ({...prev, section_b: sectionB}));
    };

    const submit = () => {
        setShow(false);

        (async () => {
            try {
                setIsLoading(true);
                await AxiosService.patch(`${serverUrl}/update-employee-exit-form-configuration/employee/${selectedEmployee?.id}`, { configuration: configurations });
                const response = await AxiosService.get(`${serverUrl}/exit-form-configurations/active`);
                setConfigurations(response?.exit_form_configuration);
                setExistingEmployees(response?.exiting_employees);
                setIsLoading(false);
            } catch (error) {
                toast.error(error?.message);
            }
        })();
    }

    return (
        <div className="main-content">
            <BreadCrumb
                title="Exiting Employees"
                parentLink="#"
                parentTitle="Pages"
                currentTitle="Exiting"
            />
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
                {
                    isLoading ? <Loader /> :
                        existingEmployees?.map((employee) => (
                            <div className="col-md-3" key={employee?.id}>
                                <div className="card card-profile-1 mb-4">
                                    <div className="card-body text-center">
                                        <div className="avatar box-shadow-2 mb-3">
                                            <img src={face1} alt="" />
                                        </div>
                                        <h5 className="mb-0">{uppercaseFirst(employee?.first_name)} {uppercaseFirst(employee?.last_name)}</h5>
                                        <p className="mt-0 text-muted">{employee?.designation_name}</p>
                                        <p className="mt-0 text-muted">{employee?.department_name}</p>
                                        <button className="btn btn-primary btn-rounded" onClick={() => handleViewExitingEmployee(employee)}>
                                            View Exit Form
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))
                }
            </div>

            {/* Modal Start */}
            <Modal show={show} onHide={handleClose} keyboard={false} size="lg" fullscreen>
                <Modal.Header closeButton>
                    <Modal.Title>{uppercaseFirst(selectedEmployee?.first_name)} {uppercaseFirst(selectedEmployee?.middle_name)} {uppercaseFirst(selectedEmployee?.last_name)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* Section A */}
                    <SectionA
                        selectedEmployee={selectedEmployee}
                        configurations={configurations}
                        markSectionAItemAsSigned={markSectionAItemAsSigned}
                    />
                    {/* End of Section A */}

                    {/* Section B */}                   
                    {roles?.filter((role) => role.name === 'hr').length > 0 ?
                        <>
                            <h4>{configurations?.section_b?.title}</h4>

                            {(configurations?.section_b?.data?.includes(configurations?.section_b?.data[0])) && configurations?.section_b?.data[0]?.description && <h4 className='mt-4'>{configurations?.section_b?.data[0]?.description}</h4>}
                            <div className="table-responsive">
                                <table className="table table-bordered" border="1" cellSpacing="0" cellPadding="5">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Exit Activity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (configurations?.section_b?.data?.includes(configurations?.section_b?.data[0])) &&
                                            configurations?.section_b?.data[0]?.items?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <label className="checkbox checkbox-outline-primary">
                                                                <input type="checkbox" checked={item?.is_cleared} onChange={() => markSectionBItemAsSigned(configurations?.section_b?.data[0], i)} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            {/* End of Section B */}

                            {(configurations?.section_b?.data?.includes(configurations?.section_b?.data[1])) && configurations?.section_b?.data[1]?.description && <h4 className='mt-4'>{configurations?.section_b?.data[1]?.description}</h4>}
                            <div className="table-responsive">
                                <table className="table table-bordered" border="1" cellSpacing="0" cellPadding="5">
                                    <tbody>
                                        {
                                            (configurations?.section_b?.data?.includes(configurations?.section_b?.data[1])) &&
                                            configurations?.section_b?.data[1]?.items?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <label className="checkbox checkbox-outline-primary">
                                                                <input type="checkbox" checked={item?.is_cleared} onChange={() => markSectionBItemAsSigned(configurations?.section_b?.data[1], i)} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }

                                    </tbody>
                                </table>
                            </div>
                            {/* End of Section B */}

                            {(configurations?.section_b?.data?.includes(configurations?.section_b?.data[2])) && configurations?.section_b?.data[2]?.description && <h4 className='mt-4'>{configurations?.section_b?.data[2]?.description}</h4>}
                            <div className="table-responsive">
                                <table className="table table-bordered" border="1" cellSpacing="0" cellPadding="5">
                                    <thead>
                                        <tr>
                                            <th>Yes</th>
                                            <th>N/A</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (configurations?.section_b?.data?.includes(configurations?.section_b?.data[2])) &&
                                            configurations?.section_b?.data[2]?.items?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <label className="checkbox checkbox-outline-primary">
                                                                <input type="checkbox" checked={item?.is_cleared} onChange={() => markSectionBItemAsSigned(configurations?.section_b?.data[2], i)} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="checkbox checkbox-outline-primary">
                                                                <input type="checkbox" checked={item?.not_avalilable} onChange={() => markSectionBItemAsUnAvailable(configurations?.section_b?.data[2], i)} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>{item.name}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>

                            {(configurations?.section_b?.data?.includes(configurations?.section_b?.data[3])) && configurations?.section_b?.data[3]?.description && <h4 className='mt-4'>{configurations?.section_b?.data[3]?.description}</h4>}
                            <div className="table-responsive">
                                <table className="table table-bordered" border="1" cellSpacing="0" cellPadding="5">
                                    <thead>
                                        <tr>
                                            <th>Yes</th>
                                            <th>N/A</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (configurations?.section_b?.data?.includes(configurations?.section_b?.data[3])) &&
                                            configurations?.section_b?.data[3]?.items?.map((item, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>
                                                            <label className="checkbox checkbox-outline-primary">
                                                                <input type="checkbox" checked={item?.is_cleared} onChange={() => markSectionBItemAsSigned(configurations?.section_b?.data[3], i)} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>
                                                            <label className="checkbox checkbox-outline-primary">
                                                                <input type="checkbox" checked={item?.not_avalilable} onChange={() => markSectionBItemAsUnAvailable(configurations?.section_b?.data[3], i)} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </td>
                                                        <td>{item.name}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </> :
                        <></>
                    }                
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={submit}> Save </Button>
                    <Button variant="secondary" onClick={handleClose}> Close </Button>
                </Modal.Footer>
            </Modal>

            {/** Modal End */}

            {/* Footer Start */}
            <Footer />
            {/* fotter end */}
        </div>
    );
};

export default ExitingEmployees;